import { createStore } from '../../shared/store/createStore';
var useManageAnalystFilterStore = createStore(function (set) { return ({
    isVisible: false,
    id: null,
    filterName: '',
    selectedCustomers: [],
    selectedLocations: [],
    actions: {
        setIsVisible: function (isVisible) { return set({ isVisible: isVisible }); },
        setId: function (id) { return set({ id: id }); },
        setFilterName: function (filterName) { return set({ filterName: filterName }); },
        setSelectedCustomers: function (selectedCustomers) { return set({ selectedCustomers: selectedCustomers }); },
        setSelectedLocations: function (selectedLocations) { return set({ selectedLocations: selectedLocations }); },
    },
}); }, 'ManageAnalystFilterStore');
export var useManageAnalystFilterActions = function () {
    return useManageAnalystFilterStore(function (state) { return state.actions; });
};
export var selectIsVisibleManageAnalystFilter = function () {
    return useManageAnalystFilterStore(function (state) { return state.isVisible; });
};
export var selectIdManageAnalystFilter = function () {
    return useManageAnalystFilterStore(function (state) { return state.id; });
};
export var selectFilterNameManageAnalystFilter = function () {
    return useManageAnalystFilterStore(function (state) { return state.filterName; });
};
export var selectSelectedCustomersManageAnalystFilter = function () {
    return useManageAnalystFilterStore(function (state) { return state.selectedCustomers; });
};
export var selectSelectedLocationsManageAnalystFilter = function () {
    return useManageAnalystFilterStore(function (state) { return state.selectedLocations; });
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { memo, useContext, useEffect, useState, } from 'react';
import { SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { fetchFacilitiesList, fetchFacilityAreaTree, fetchFacilityZoneTree, onChangeArea, onChangeFacility, onChangeZone, } from '../../../layout/components/Header/features';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import '../../../shared/styles/SelectsGroup/index.scss';
import { FacilitiesListStore } from '../../store/FacilitiesListStore';
import { AreaSelect } from './components/AreaSelect';
import { FacilitySelect } from './components/FacilitySelect';
import { ZoneSelect } from './components/ZoneSelect';
export var SelectsGroup = memo(function (_a) {
    var selectedFacility = _a.selectedFacility, selectedZone = _a.selectedZone, selectedArea = _a.selectedArea, layoutDispatch = _a.layoutDispatch;
    var trackEvent = useMatomo().trackEvent;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var setFacilitiesListFacilitiesListStore = FacilitiesListStore(function (state) { return state.setFacilitiesList; });
    var _b = useState(false), fetchFacility = _b[0], setFetchFacility = _b[1];
    var _c = useState(false), fetchZone = _c[0], setFetchZone = _c[1];
    var _d = useState(false), isLoadingFacility = _d[0], setIsLoadingFacility = _d[1];
    var _e = useState(false), isLoadingZone = _e[0], setIsLoadingZone = _e[1];
    var _f = useState(false), isLoadingArea = _f[0], setIsLoadingArea = _f[1];
    var _g = useState([]), facilityList = _g[0], setFacilityList = _g[1];
    var _h = useState([]), zones = _h[0], setZones = _h[1];
    var _j = useState([]), areas = _j[0], setAreas = _j[1];
    var _k = useState(null), showAmazon = _k[0], setShowAmazon = _k[1];
    useEffect(function () {
        if (selectedSystemType === SYSTEM_TYPE_REMASTERED) {
            return;
        }
        fetchFacilitiesList(setFetchFacility, setFetchZone, setFacilityList, setZones, setAreas, setShowAmazon, setFacilitiesListFacilitiesListStore, setIsLoadingFacility, setIsLoadingZone, setIsLoadingArea);
    }, [selectedSystemType]);
    useEffect(function () {
        if (selectedZone && fetchZone) {
            setShowAmazon('area');
            fetchFacilityAreaTree(selectedFacility, selectedZone, function (resp) {
                setAreas(resp.areas || []);
            }, setIsLoadingArea);
            return;
        }
        setShowAmazon('zone');
    }, [selectedZone, fetchZone]);
    useEffect(function () {
        if (selectedFacility && fetchFacility) {
            setShowAmazon('zone');
            fetchFacilityZoneTree(selectedFacility, function (resp) {
                setZones(resp.zones || []);
            }, setShowAmazon, setFetchZone, setZones, setAreas, setIsLoadingZone, setIsLoadingArea);
            return;
        }
        setShowAmazon('facility');
    }, [selectedFacility, fetchFacility]);
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "gap-3 selects-group", children: [_jsx(FacilitySelect, { isLoading: isLoadingFacility, facilityList: facilityList, selectedFacility: selectedFacility, onChangeFacility: function (name) { return onChangeFacility(layoutDispatch, name, trackEvent); } }), _jsx("div", { className: "".concat(showAmazon === 'zone' || showAmazon === 'area' ? '' : 'hidden'), children: _jsx(ZoneSelect, { isLoading: isLoadingZone, zoneList: zones, selectedZone: selectedZone, onChangeZone: function (name) { return onChangeZone(layoutDispatch, name, trackEvent); } }) }), _jsx("div", { className: "".concat(showAmazon === 'area' ? '' : 'hidden'), children: _jsx(AreaSelect, { isLoading: isLoadingArea, areaList: areas, selectedArea: selectedArea, onChangeArea: function (name) { return onChangeArea(layoutDispatch, name, trackEvent); } }) })] }) }));
});
SelectsGroup.displayName = 'SelectsGroup';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { cloneDeep as _cloneDeep, get as _get, size as _size } from 'lodash';
import { memo, useContext, useEffect, useMemo, useState, } from 'react';
import ReactDOMServer from 'react-dom/server';
import Select from 'react-select';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../../../api/endpoints/endpoints';
import { METRIC_KEY, SYSTEM_TYPES_DATA, ZOOM_OPTIONS } from '../../../../../../constants';
import Loader from '../../../../../../shared/components/Loader';
import { RmsTooltipFormatter, positioner, } from '../../../../../../shared/components/charts/chartsWrapper/components/shared/tooltip';
import { calculateMaxYAxisValue, calculateMinYAxisValue, } from '../../../../../../shared/components/charts/chartsWrapper/features/shared/extremes';
import { DEFAULT_CHARTS_CONFIG } from '../../../../../../shared/config/charts/defaultChartsConfig';
import { SystemTypeContext } from '../../../../../../shared/context/SystemTypeContext';
import { getRmsAccVelExtraType } from '../../../../../../shared/features/charts/shared';
import { getTimezone } from '../../../../../../shared/features/shared';
import { getUoms, isCfChart, isPinChart } from '../../../../../../shared/helper/chart';
import { InstallationPointStore } from '../../../../../../shared/store/charts/chartsContent/InstallationPointStore';
import { selectChartZoomDays } from '../../../../../../shared/store/charts/useChartSettingsStore';
import { selectMeasure, selectPersonalSettingMeasure, } from '../../../../../../shared/store/global/useGlobalStore';
import { transformLevelsArrayToObject } from '../lib';
var Chart = function (_a) {
    var selectedInstallationPointId = _a.selectedInstallationPointId, selectedReadingTypeIds = _a.selectedReadingTypeIds, setSelectedReadingTypeIds = _a.setSelectedReadingTypeIds, chartIdentifiers = _a.chartIdentifiers, alertLevels = _a.alertConditions, shouldRefresh = _a.shouldRefresh, customerLevels = _a.customerLevels, isVisibleCustomerLevels = _a.isVisibleCustomerLevels;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var _b = useState(true), loader = _b[0], setLoader = _b[1];
    var alertConditions = useMemo(function () {
        return transformLevelsArrayToObject(alertLevels);
    }, [alertLevels]);
    var chartZoomDays = selectChartZoomDays();
    var _c = useState(chartZoomDays), countDays = _c[0], setCountDays = _c[1];
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var _d = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        readingTypes: state.readingTypes,
    }); }, shallow), installationPoint = _d.installationPoint, readingTypes = _d.readingTypes;
    var timezone = getTimezone(installationPoint, selectedSystemType);
    var plotOptionsConfig = useMemo(function () {
        return __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions), { series: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions.series), { connectNulls: true }), line: {
                events: {
                    legendItemClick: function (e) {
                        var _this = this;
                        e.preventDefault();
                        if (!selectedReadingTypeIds.includes(this.userOptions.chartIdentifier)) {
                            setSelectedReadingTypeIds(__spreadArray(__spreadArray([], selectedReadingTypeIds, true), [this.userOptions.chartIdentifier], false));
                            return;
                        }
                        if (selectedReadingTypeIds.length === 1) {
                            return;
                        }
                        setSelectedReadingTypeIds(selectedReadingTypeIds.filter(function (readingTypeId) { return readingTypeId !== _this.userOptions.chartIdentifier; }));
                    },
                },
            } });
    }, [selectedReadingTypeIds]);
    var _e = useState(__assign(__assign({}, DEFAULT_CHARTS_CONFIG), { chart: {
            borderColor: 'rgb(241, 238, 233)',
            backgroundColor: 'rgb(241, 238, 233)',
            animation: false,
        }, tooltip: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.tooltip), { positioner: function (labelWidth, labelHeight, point) {
                // @ts-ignore
                return positioner(labelWidth, labelHeight, point, this.chart);
            }, formatter: function () {
                return ReactDOMServer.renderToStaticMarkup((_jsx(RmsTooltipFormatter
                // @ts-ignore
                , { 
                    // @ts-ignore
                    points: this.points, 
                    // @ts-ignore
                    allPoints: this.points, prefix: prefix, isVisibleLocker: false, timezone: timezone !== null && timezone !== void 0 ? timezone : 'America/New_York', 
                    // @ts-ignore
                    xValue: this.x, 
                    // @ts-ignore
                    readingTypes: readingTypes })));
            } }), exporting: {
            enabled: false,
        }, legend: {
            enabled: false,
            x: 0,
        }, yAxis: [
            {
                title: {
                    text: "".concat(_get(readingTypes, [selectedReadingTypeIds[0], 'title'], ''), " ").concat(isCfChart(selectedReadingTypeIds[0]) || isPinChart(selectedReadingTypeIds[0])
                        ? ''
                        : "(".concat(getUoms(selectedReadingTypeIds[0], measure, personalSettingMeasure, readingTypes), ") ").concat(getRmsAccVelExtraType(selectedReadingTypeIds[0]))),
                },
                softMax: null,
                softMin: null,
                plotLines: [
                    {
                        className: 'alarm-plot-line caution',
                        level: 'caution',
                        value: null,
                        zIndex: 4,
                    },
                    {
                        className: 'alarm-plot-line warning',
                        level: 'warning',
                        value: null,
                        zIndex: 4,
                    },
                    {
                        className: 'caution',
                        level: 'cautionCustomer',
                        value: null,
                        zIndex: 4,
                        label: {
                            text: 'Caution from self-service',
                            align: 'right',
                            y: 12,
                        },
                        color: 'rgb(236, 151, 31)',
                    },
                    {
                        className: 'warning',
                        level: 'warningCustomer',
                        value: null,
                        zIndex: 4,
                        label: {
                            text: 'Warning from self-service',
                            align: 'right',
                        },
                        color: 'rgb(255, 0, 0)',
                    },
                ],
            },
        ], plotOptions: plotOptionsConfig })), options = _e[0], setOptions = _e[1];
    var fetch = function (countDays, measure) {
        setLoader(true);
        Endpoints[selectedSystemType]
            .getRmsReadingsByReadingTypes(selectedInstallationPointId, countDays, {
            query: {
                readingTypes: chartIdentifiers,
                selected_facility_metric: METRIC_KEY[measure],
                timezone: timezone,
            },
        })
            .then(function (resp) {
            var _a;
            if (_size(resp.data) > 0) {
                var series_1 = [];
                Object.values(resp.data).map(function (dataItem, index) {
                    var chartIdentifier = +Object.keys(resp.data)[index];
                    series_1.push({
                        data: dataItem.readings,
                        color: selectedReadingTypeIds.length === 1 && selectedReadingTypeIds.includes(+chartIdentifier)
                            ? '#2f7ed8'
                            : _get(DEFAULT_CHARTS_CONFIG, ['colors', index], '#2f7ed8'),
                        visible: selectedReadingTypeIds.includes(+chartIdentifier),
                        name: _get(readingTypes, [Object.keys(resp.data)[index], 'title'], '') +
                            ' ' +
                            _get(readingTypes, [Object.keys(resp.data)[index], 'axis'], ''),
                        showInLegend: true,
                        chartIdentifier: chartIdentifier,
                        dataGrouping: {
                            approximation: 'high',
                            forced: true,
                        },
                    });
                });
                var plotLines = _cloneDeep((_a = options.yAxis[0]) === null || _a === void 0 ? void 0 : _a.plotLines) || [];
                var plotLinesNotExists_1 = selectedReadingTypeIds.length === 1 &&
                    _size(plotLines) !== 0 &&
                    _size(_get(resp.data, [selectedReadingTypeIds[0], 'alertConditions'], [])) === 0;
                plotLines.map(function (plotLine) {
                    if (plotLinesNotExists_1) {
                        plotLine.className += ' not-exist';
                    }
                    else {
                        plotLine.className = plotLine.className.replace('not-exist', '');
                    }
                });
                setOptions(__assign(__assign({}, options), { legend: __assign(__assign({}, options.legend), { enabled: chartIdentifiers.length > 1 }), series: series_1, yAxis: [
                        __assign(__assign({}, options.yAxis[0]), { plotLines: plotLines }),
                    ] }));
            }
            setLoader(false);
        })
            .catch(function () {
            setLoader(false);
        });
    };
    useEffect(function () {
        fetch(countDays, measure);
    }, [selectedInstallationPointId, countDays]);
    useEffect(function () {
        if (shouldRefresh) {
            fetch(countDays, measure);
        }
    }, [shouldRefresh]);
    useEffect(function () {
        if (options.series.length === 0) {
            return;
        }
        var updatedSeries = _cloneDeep(options.series);
        updatedSeries.map(function (seriesItem, index) {
            seriesItem.color =
                selectedReadingTypeIds.length === 1 && selectedReadingTypeIds.includes(+seriesItem.chartIdentifier)
                    ? '#2f7ed8'
                    : _get(DEFAULT_CHARTS_CONFIG, ['colors', index], '#2f7ed8');
            seriesItem.visible = selectedReadingTypeIds.includes(+seriesItem.chartIdentifier);
        });
        setOptions(__assign(__assign({}, options), { series: updatedSeries, plotOptions: plotOptionsConfig }));
    }, [selectedReadingTypeIds]);
    useEffect(function () {
        var _a;
        if (loader) {
            return;
        }
        var plotLines = [];
        Object.keys(alertConditions).forEach(function (key) {
            var levels = alertConditions[key].levels;
            plotLines.push({
                className: 'alarm-plot-line caution',
                level: 'caution',
                value: levels.caution,
                zIndex: 4,
            }, {
                className: 'alarm-plot-line warning',
                level: 'warning',
                value: levels.warning,
                zIndex: 4,
            });
        });
        if (isVisibleCustomerLevels) {
            var customerCaution = customerLevels.caution, customerWarning = customerLevels.warning;
            plotLines.push({
                className: 'caution',
                level: 'cautionCustomer',
                value: customerCaution,
                zIndex: 4,
                label: {
                    text: 'Caution from self-service',
                    align: 'right',
                    y: 12,
                },
                color: 'rgb(236, 151, 31)',
            }, {
                className: 'warning',
                level: 'warningCustomer',
                value: customerWarning,
                zIndex: 4,
                label: {
                    text: 'Warning from self-service',
                    align: 'right',
                },
                color: 'rgb(255, 0, 0)',
            });
        }
        setOptions(__assign(__assign({}, options), { yAxis: [
                __assign(__assign({}, options.yAxis[0]), { plotLines: plotLines, softMax: calculateMaxYAxisValue(alertConditions, selectedReadingTypeIds[0], (_a = options.series[0]) === null || _a === void 0 ? void 0 : _a.data, readingTypes, measure, personalSettingMeasure), softMin: calculateMinYAxisValue(alertConditions, selectedReadingTypeIds[0], readingTypes, measure, personalSettingMeasure) }),
            ] }));
    }, [alertConditions, options.series, isVisibleCustomerLevels, customerLevels]);
    var customStyles = useMemo(function () { return ({
        control: function (provided) { return (__assign(__assign({}, provided), { width: '94px', fontSize: '14px' })); },
    }); }, []);
    var onChangeRange = function (value) {
        setCountDays(value);
    };
    var isInListZoomValue = useMemo(function () { return ZOOM_OPTIONS.filter(function (el) { return el.value === (countDays || ZOOM_OPTIONS[3].value); }); }, [countDays]);
    return (_jsxs("div", { className: "change-alarm-levels-chart", children: [_jsx("div", { className: "change-alarm-levels-chart-buttons", children: _jsx(Select, { styles: customStyles, id: "chart-zoom", isSearchable: false, classNamePrefix: "customSelectStyleDefault", options: ZOOM_OPTIONS, onChange: function (ev) { return onChangeRange(ev.value); }, value: isInListZoomValue.length
                        ? isInListZoomValue
                        : {
                            value: chartZoomDays,
                            label: chartZoomDays + 'd',
                        } }) }), _jsxs("div", { className: "position-relative", children: [loader ? (_jsx("div", { className: "position-absolute d-flex justify-content-center align-items-center h-100 w-100", style: {
                            zIndex: 1,
                            backdropFilter: 'blur(2px)',
                        }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})), _jsx(HighchartsReact, { highcharts: Highcharts, options: options })] })] }));
};
export default memo(Chart);

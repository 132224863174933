import { useQuery } from '@tanstack/react-query';
import Api from '../../../api/api';
export var analystFiltersKey = function () { return ['analystFilters']; };
var analystFiltersFn = function () { return Api.get('all-analytics/analyst-filter'); };
export var useAnalystFiltersQuery = function () {
    return useQuery({
        queryKey: analystFiltersKey(),
        queryFn: analystFiltersFn,
        select: function (_a) {
            var list = _a.list;
            return {
                list: list.map(function (el) {
                    var _a, _b;
                    return {
                        selectedCustomers: ((_a = el === null || el === void 0 ? void 0 : el.data.customers) !== null && _a !== void 0 ? _a : []).map(function (_a) {
                            var customer_id = _a.customer_id;
                            return customer_id;
                        }),
                        selectedLocations: (_b = el === null || el === void 0 ? void 0 : el.data) === null || _b === void 0 ? void 0 : _b.selectedLocation,
                        label: el.label,
                        id: el.value,
                    };
                }),
            };
        },
        placeholderData: {
            list: [],
        },
    });
};

import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var locationsKey = function (selectedCustomers) { return [
    'locations',
    { selectedCustomers: selectedCustomers },
]; };
var locationsFn = function (_a) {
    var queryKey = _a.queryKey;
    var selectedCustomers = queryKey[1].selectedCustomers;
    return Api.get("all-analytics/location/get-tree", {
        query: {
            selected_customer_ids: selectedCustomers,
        },
    });
};
export var useLocationsTreeQuery = function (_a) {
    var selectedCustomers = _a.selectedCustomers;
    return useQuery({
        queryKey: locationsKey(selectedCustomers),
        queryFn: locationsFn,
        select: function (_a) {
            var list = _a.list;
            return {
                list: list !== null && list !== void 0 ? list : [],
            };
        },
        placeholderData: {
            list: [],
        },
        enabled: !!(selectedCustomers && selectedCustomers.length),
    });
};

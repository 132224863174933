import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDebounce } from '@uidotdev/usehooks';
import { memo, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Input, Label } from 'reactstrap';
import Loader from '../../../../../../../shared/components/Loader';
import { useCustomersQuery } from '../../../../../../services/useCustomersQuery';
import { getAllChildrenIds, getAllChildrenLabels, isActive, isHalfActive } from '../../features';
export var LocationsSelectTree = memo(function (_a) {
    var locationsTreeData = _a.locationsTreeData, _b = _a.filteredLocations, filteredLocations = _b === void 0 ? [] : _b, onChangeHandler = _a.onChangeHandler, selectedLocations = _a.selectedLocations, locationsTreeIsLoading = _a.locationsTreeIsLoading, locationsTreeIsFetching = _a.locationsTreeIsFetching;
    var list = locationsTreeData.list;
    var _c = useState(''), searchTerm = _c[0], setSearchTerm = _c[1];
    var debouncedSearchTerm = useDebounce(searchTerm, 100);
    var customersData = useCustomersQuery().data;
    var customers = customersData.customers;
    var handleChange = function (e) {
        setSearchTerm(e.target.value);
    };
    var isShow = function (item) {
        if (!(filteredLocations.includes(item.value) ||
            getAllChildrenIds(item).some(function (val) { return filteredLocations.includes(val); }) ||
            !filteredLocations.length)) {
            return false;
        }
        return (item.label.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            getAllChildrenLabels(item).some(function (val) {
                return val.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
            }) ||
            !debouncedSearchTerm);
    };
    var usedCustomersList = {};
    var getBlock = function (list) {
        return (_jsx(_Fragment, { children: list.map(function (item) {
                var _a, _b;
                var isVisibleCustomerHeader = false;
                if (!usedCustomersList[item.customer_id] && item.level_name === 'facility') {
                    usedCustomersList[item.customer_id] = true;
                    isVisibleCustomerHeader = true;
                }
                return (_jsx(_Fragment, { children: isShow(item) ? (_jsxs(_Fragment, { children: [isVisibleCustomerHeader && (_jsx("div", { className: "customer-name", children: (_b = (_a = customers.find(function (customer) { return customer.customer_id === item.customer_id; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null })), _jsxs("span", { onClick: function (event) {
                                    onChangeHandler(item);
                                    event.stopPropagation();
                                }, className: "checkbox-type ".concat(item.children.length ? 'parent' : '', " ").concat(isActive(item, selectedLocations)
                                    ? 'active'
                                    : isHalfActive(item, selectedLocations, filteredLocations)
                                        ? 'half-active'
                                        : ''), children: [item.label, item.children.length ? (_jsx("div", { className: "d-flex flex-column", children: getBlock(item.children) })) : null] }, item.value)] })) : (_jsx(_Fragment, {})) }));
            }) }));
    };
    return (_jsx(_Fragment, { children: list.length ? (_jsxs(_Fragment, { children: [_jsx("search", { children: _jsxs(Label, { for: "location-select-search", children: [_jsx(FaSearch, {}), _jsx(Input, { autoFocus: true, autoComplete: "off", type: "search", id: "location-select-search", placeholder: "Search in filter", onChange: handleChange, style: { backgroundColor: 'transparent' } })] }) }), _jsx("section", { children: getBlock(list) }), _jsx("footer", { children: _jsx("span", { onClick: function () { return onChangeHandler(null); }, children: "Reset" }) })] })) : (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: locationsTreeIsLoading || locationsTreeIsFetching ? (_jsx(Loader, { variant: "loader-md" })) : (_jsx("div", { className: "text-center", children: "Nothing to show" })) })) }));
});
LocationsSelectTree.displayName = 'LocationsSelectTree';

import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ModalsStore } from '../../../../../store/charts/chartsContent/ModalsStore';
var MachineInformation = function () {
    var setModalsStoreStates = ModalsStore(function (state) { return ({
        setModalsStoreStates: state.setStates,
    }); }, shallow).setModalsStoreStates;
    return (_jsx(Button, { outline: true, color: "customChartTopBarSelect", className: "position-relative btn-chart-tools", size: "sm", onClick: function () { return setModalsStoreStates({ isVisibleMachineInfoModal: true }); }, children: "Machine Information" }));
};
export default React.memo(MachineInformation);

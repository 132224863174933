import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import Loader from '../../../../../shared/components/Loader';
import { ZoneSelectModal } from './components/ZoneSelectModal';
export var ZoneSelect = memo(function (_a) {
    var isLoading = _a.isLoading, zoneList = _a.zoneList, selectedZone = _a.selectedZone, onChangeZone = _a.onChangeZone;
    var _b = useState(false), isVisibleModal = _b[0], setIsVisibleModal = _b[1];
    return (_jsx("div", { className: "header-select", children: isLoading ? (_jsx("span", { children: _jsx(Loader, { variant: "loader-xs" }) })) : (_jsxs(_Fragment, { children: [_jsxs("span", { onClick: function () { return setIsVisibleModal(true); }, children: [selectedZone !== null && selectedZone !== void 0 ? selectedZone : 'Zone - ' + zoneList.length, _jsx(FaAngleDown, {})] }), isVisibleModal ? (_jsx(ZoneSelectModal, { onClose: function () { return setIsVisibleModal(false); }, zoneList: zoneList, onChangeZone: onChangeZone, selectedZone: selectedZone })) : (_jsx(_Fragment, {}))] })) }));
});
ZoneSelect.displayName = 'ZoneSelect';

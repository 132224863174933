var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { set as _set } from '#typed/lodash';
import { Badge, Button, Col, Input, Label, Row } from '#typed/reactstrap';
import { useContext, useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamation, FaInfo, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { READING_TYPES, READING_TYPE_TACHOMETER } from '../../../../../constants';
import { SystemTypeContext } from '../../../../../shared/context/SystemTypeContext';
import { ChartFeaturesStore } from '../../../../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../../../../shared/store/charts/chartsContent/InstallationPointStore';
import { useConfirmModalActions } from '../../../../../shared/store/global/useConfirmModalStore';
import { LossPacketsAlert } from '../../../../../widgets/common';
import './AlertLevelsTab.scss';
import AlertLevelBlock from './components/AlertLevelBlock';
import Chart from './components/Chart';
import { DEFAULT_ALERT_CONDITION } from './constants';
import { useCustomerLevels } from './hooks/useCustomerLevels';
import { useValidationErrors } from './hooks/useValidationErrors';
import { isNumeric, transformLevelsObjectToArray } from './lib';
export var AlertLevelsTab = function (_a) {
    var setSmallLoader = _a.setSmallLoader, selectedInstallationPointId = _a.installationPointId, selectedReadingTypeIds = _a.selectedReadingTypeIds, onSelectReadingType = _a.onSelectReadingType, chartIdentifiers = _a.chartIdentifiers, isAdapter = _a.isAdapter;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
        setChartFeatures: state.setStates,
    }); }, shallow), changeAlertConditions = _b.changeAlertConditions, setChartFeatures = _b.setChartFeatures;
    var readingTypeId = changeAlertConditions.readingTypeId;
    var _c = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        overlayInstallationPoints: state.overlayInstallationPoints,
        packetLossPercent: state.packetLossPercent,
    }); }, shallow), installationPoint = _c.installationPoint, overlayInstallationPoints = _c.overlayInstallationPoints, packetLossPercent = _c.packetLossPercent;
    var _d = useState(false), forAllSensors = _d[0], setForAllSensors = _d[1];
    var _e = useState(false), forOverlaid = _e[0], setForOverlaid = _e[1];
    var _f = useState(false), areAlertConditionsFilled = _f[0], setAlertConditionsFilled = _f[1];
    var _g = useState([
        {
            caution: structuredClone(DEFAULT_ALERT_CONDITION),
            warning: structuredClone(DEFAULT_ALERT_CONDITION),
        },
    ]), alertLevels = _g[0], setAlertLevels = _g[1];
    var validationErrors = useValidationErrors({ alertLevels: alertLevels }).validationErrors;
    useEffect(function () {
        if (!areAlertConditionsFilled) {
            setAlertLevels(transformLevelsObjectToArray(changeAlertConditions));
            setAlertConditionsFilled(true);
        }
    }, [changeAlertConditions, areAlertConditionsFilled]);
    var hasAutoAi = alertLevels.some(function (_a) {
        var _b, _c;
        var caution = _a.caution, warning = _a.warning;
        return ((_b = caution === null || caution === void 0 ? void 0 : caution.settings) === null || _b === void 0 ? void 0 : _b.is_ai) || ((_c = warning === null || warning === void 0 ? void 0 : warning.settings) === null || _c === void 0 ? void 0 : _c.is_ai);
    });
    var triggerEachReading = alertLevels.some(function (_a) {
        var _b, _c;
        var caution = _a.caution, warning = _a.warning;
        return Number((_b = caution === null || caution === void 0 ? void 0 : caution.settings) === null || _b === void 0 ? void 0 : _b.trigger_time) > 0 || Number((_c = warning === null || warning === void 0 ? void 0 : warning.settings) === null || _c === void 0 ? void 0 : _c.trigger_time) > 0;
    });
    var _h = useState([]), comments = _h[0], setComments = _h[1];
    useEffect(function () {
        setSmallLoader(true);
        if (readingTypeId) {
            Endpoints[selectedSystemType]
                .getAlertConditionComments({
                query: {
                    installationPointIds: [installationPoint.id],
                    readingTypeIds: [readingTypeId],
                },
            })
                .then(function (response) {
                if (response === null || response === void 0 ? void 0 : response.data) {
                    setComments(response.data);
                }
            })
                .finally(function () {
                setSmallLoader(false);
            });
        }
    }, [installationPoint.id, readingTypeId]);
    var _j = useCustomerLevels({
        installationPointId: installationPoint.id,
        readingTypeId: readingTypeId,
    }), customerLevels = _j.customerLevels, isVisibleCustomerLevels = _j.isVisibleCustomerLevels, setIsVisibleCustomerLevels = _j.setIsVisibleCustomerLevels;
    var canAddMoreAlertLevels = alertLevels.length === 1;
    var _k = useState(false), shouldRefreshChart = _k[0], setShouldRefreshChart = _k[1];
    var addAlertLevel = function () {
        setAlertLevels(function (alertLevels) {
            return __spreadArray(__spreadArray([], alertLevels, true), [
                {
                    caution: structuredClone(DEFAULT_ALERT_CONDITION),
                    warning: structuredClone(DEFAULT_ALERT_CONDITION),
                },
            ], false);
        });
    };
    var changeNewAlertCondition = function (index, level, path, value) {
        var changedAlertConditionsState = structuredClone(alertLevels);
        _set(changedAlertConditionsState, __spreadArray([index, level], path, true), value);
        setAlertLevels(changedAlertConditionsState);
    };
    var updateAlertConditionsOnChart = function (shouldCloseModal) {
        if (shouldCloseModal === void 0) { shouldCloseModal = false; }
        if (readingTypeId) {
            Endpoints[selectedSystemType]
                .getAlertLevels(installationPoint.id, readingTypeId)
                .then(function (_a) {
                var alertConditions = _a.alertConditions;
                setChartFeatures({
                    changeAlertConditions: __assign(__assign(__assign({}, changeAlertConditions), { readingTypeId: readingTypeId, selectedReadingTypeIds: selectedReadingTypeIds, isVisibleModal: !shouldCloseModal, shouldUpdate: true }), alertConditions),
                });
            });
        }
    };
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var removeLevelsSecondPairOfLevels = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure to delete alert conditions?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                setAlertLevels(function (alertConditions) {
                    return alertConditions.filter(function (_, i) { return i !== 1; });
                });
            },
        });
    };
    var deleteAlertCondition = function (index, level, alertCondition) {
        var _a;
        var alertConditionId = (_a = alertCondition === null || alertCondition === void 0 ? void 0 : alertCondition.settings) === null || _a === void 0 ? void 0 : _a.alert_condition_id;
        var removeAlertCondition = function () {
            setAlertLevels(function (alertLevels) {
                return alertLevels.map(function (alertLevel, alertLevelIndex) {
                    var _a;
                    if (alertLevelIndex === index) {
                        return __assign(__assign({}, alertLevel), (_a = {}, _a[level] = structuredClone(DEFAULT_ALERT_CONDITION), _a));
                    }
                    return alertLevel;
                });
            });
            updateAlertConditionsOnChart();
        };
        if (alertConditionId && readingTypeId) {
            setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure to delete alert condition?',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    setSmallLoader(true);
                    Endpoints[selectedSystemType]
                        .deleteOnceAlertLevel({
                        alertConditionId: alertConditionId,
                        installationPointId: installationPoint.id,
                        readingTypeId: readingTypeId,
                    })
                        .then(function (_a) {
                        var success = _a.success;
                        if (success) {
                            toast.success('Alert condition successfully removed', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                            removeAlertCondition();
                        }
                    })
                        .finally(function () {
                        setSmallLoader(false);
                    });
                },
            });
        }
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        if (validationErrors.length)
            return;
        var action = event.nativeEvent.submitter.dataset.action;
        var shouldCloseModal = action === 'save&close';
        var submitData = function () {
            setSmallLoader(true);
            var requests = [];
            if (readingTypeId) {
                alertLevels.forEach(function (levels) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
                    var caution = levels.caution, warning = levels.warning;
                    var shouldInOneRequest = [(_a = caution === null || caution === void 0 ? void 0 : caution.settings) === null || _a === void 0 ? void 0 : _a.alert_condition_id, (_b = warning === null || warning === void 0 ? void 0 : warning.settings) === null || _b === void 0 ? void 0 : _b.alert_condition_id].filter(Boolean)
                        .length === 0;
                    if (shouldInOneRequest && isNumeric(caution.level) && isNumeric(warning.level)) {
                        var newLevels = {
                            caution: {
                                alert_level: 'caution',
                                reading_type_id: readingTypeId,
                                condition: caution.condition,
                                settings: {
                                    is_ai: (_d = (_c = caution.settings) === null || _c === void 0 ? void 0 : _c.is_ai) !== null && _d !== void 0 ? _d : 0,
                                    ai_urgency: (_f = (_e = caution.settings) === null || _e === void 0 ? void 0 : _e.ai_urgency) !== null && _f !== void 0 ? _f : 0,
                                    is_pre_ai: (_h = (_g = caution.settings) === null || _g === void 0 ? void 0 : _g.is_pre_ai) !== null && _h !== void 0 ? _h : 0,
                                    trigger_time: (_k = (_j = caution.settings) === null || _j === void 0 ? void 0 : _j.trigger_time) !== null && _k !== void 0 ? _k : null,
                                    ai_description: (_m = (_l = caution.settings) === null || _l === void 0 ? void 0 : _l.ai_description) !== null && _m !== void 0 ? _m : '',
                                    trigger_rms_od: (_p = (_o = caution.settings) === null || _o === void 0 ? void 0 : _o.trigger_rms_od) !== null && _p !== void 0 ? _p : null,
                                },
                                value: caution.level,
                                chart_type: readingTypeId,
                            },
                            warning: {
                                alert_level: 'warning',
                                reading_type_id: readingTypeId,
                                condition: warning.condition,
                                settings: {
                                    is_ai: (_r = (_q = warning.settings) === null || _q === void 0 ? void 0 : _q.is_ai) !== null && _r !== void 0 ? _r : 0,
                                    ai_urgency: (_t = (_s = warning.settings) === null || _s === void 0 ? void 0 : _s.ai_urgency) !== null && _t !== void 0 ? _t : 0,
                                    is_pre_ai: (_v = (_u = warning.settings) === null || _u === void 0 ? void 0 : _u.is_pre_ai) !== null && _v !== void 0 ? _v : 0,
                                    trigger_time: (_x = (_w = warning.settings) === null || _w === void 0 ? void 0 : _w.trigger_time) !== null && _x !== void 0 ? _x : null,
                                    ai_description: (_z = (_y = warning.settings) === null || _y === void 0 ? void 0 : _y.ai_description) !== null && _z !== void 0 ? _z : '',
                                    trigger_rms_od: (_1 = (_0 = warning.settings) === null || _0 === void 0 ? void 0 : _0.trigger_rms_od) !== null && _1 !== void 0 ? _1 : null,
                                },
                                value: warning.level,
                                chart_type: readingTypeId,
                            },
                        };
                        if (caution.isRequestFFT) {
                            newLevels.caution.fftSettings = caution.fftSettings;
                        }
                        if (warning.isRequestFFT) {
                            newLevels.warning.fftSettings = warning.fftSettings;
                        }
                        requests.push(Endpoints[selectedSystemType].createOnceAlertLevel({
                            installationPointId: installationPoint.id,
                            readingTypeId: readingTypeId,
                            levels: newLevels,
                            setForAllComponents: +forOverlaid,
                            setForAllEquipment: +forAllSensors,
                            overlayInstallationPoints: overlayInstallationPoints.map(function (_a) {
                                var id = _a.id;
                                return id;
                            }),
                        }, { query: { duplicated: true } }));
                    }
                    else {
                        Object.entries(levels)
                            .filter(function (_a) {
                            var _ = _a[0], level = _a[1];
                            return level && isNumeric(level.level);
                        })
                            .forEach(function (_a) {
                            var _b, _c;
                            var _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
                            var alertLevelKey = _a[0], alertLevel = _a[1];
                            var alertLevelPayload = {
                                alert_level: alertLevelKey,
                                reading_type_id: readingTypeId,
                                condition: alertLevel.condition,
                                settings: {
                                    is_ai: (_e = (_d = alertLevel.settings) === null || _d === void 0 ? void 0 : _d.is_ai) !== null && _e !== void 0 ? _e : 0,
                                    ai_urgency: (_g = (_f = alertLevel.settings) === null || _f === void 0 ? void 0 : _f.ai_urgency) !== null && _g !== void 0 ? _g : 0,
                                    is_pre_ai: (_j = (_h = alertLevel.settings) === null || _h === void 0 ? void 0 : _h.is_pre_ai) !== null && _j !== void 0 ? _j : 0,
                                    trigger_time: (_l = (_k = alertLevel.settings) === null || _k === void 0 ? void 0 : _k.trigger_time) !== null && _l !== void 0 ? _l : null,
                                    ai_description: (_o = (_m = alertLevel.settings) === null || _m === void 0 ? void 0 : _m.ai_description) !== null && _o !== void 0 ? _o : '',
                                    trigger_rms_od: (_q = (_p = alertLevel.settings) === null || _p === void 0 ? void 0 : _p.trigger_rms_od) !== null && _q !== void 0 ? _q : null,
                                },
                                value: alertLevel.level,
                                chart_type: readingTypeId,
                            };
                            var alertLevelId = (_r = alertLevel.settings) === null || _r === void 0 ? void 0 : _r.alert_condition_id;
                            if (alertLevel.isRequestFFT) {
                                alertLevelPayload.fftSettings = alertLevel.fftSettings;
                            }
                            if (alertLevelId) {
                                requests.push(Endpoints[selectedSystemType].updateOnceAlertLevel({
                                    installationPointId: installationPoint.id,
                                    readingTypeId: readingTypeId,
                                    levels: (_b = {},
                                        _b[alertLevelKey] = __assign(__assign({}, alertLevelPayload), { id: alertLevelId }),
                                        _b),
                                    setForAllEquipment: +forAllSensors,
                                    setForAllComponents: +forOverlaid,
                                    overlayInstallationPoints: overlayInstallationPoints.map(function (_a) {
                                        var id = _a.id;
                                        return id;
                                    }),
                                }, { query: { duplicated: true } }));
                            }
                            else {
                                requests.push(Endpoints[selectedSystemType].createOnceAlertLevel({
                                    installationPointId: installationPoint.id,
                                    readingTypeId: readingTypeId,
                                    levels: (_c = {},
                                        _c[alertLevelKey] = alertLevelPayload,
                                        _c),
                                    setForAllEquipment: +forAllSensors,
                                    setForAllComponents: +forOverlaid,
                                    overlayInstallationPoints: overlayInstallationPoints.map(function (_a) {
                                        var id = _a.id;
                                        return id;
                                    }),
                                }, { query: { duplicated: true } }));
                            }
                        });
                    }
                });
            }
            Promise.all(requests)
                .then(function (responses) {
                var allSucceed = responses.every(function (_a) {
                    var success = _a.success;
                    return success;
                });
                if (allSucceed && readingTypeId) {
                    toast.success('Successfully saved', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    if (selectedInstallationPointId === installationPoint.id) {
                        setShouldRefreshChart(true);
                        updateAlertConditionsOnChart(shouldCloseModal);
                    }
                }
                responses
                    .map(function (_a) {
                    var errors = _a.errors;
                    return errors;
                })
                    .flat(1)
                    .forEach(function (_a) {
                    var value = _a.value;
                    toast.error(value || 'Something went wrong', {
                        icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
                    });
                });
            })
                .finally(function () {
                setSmallLoader(false);
            });
        };
        var isAutoPreAi = alertLevels.some(function (_a) {
            var _b, _c;
            var caution = _a.caution, warning = _a.warning;
            return ((_b = caution === null || caution === void 0 ? void 0 : caution.settings) === null || _b === void 0 ? void 0 : _b.is_pre_ai) || ((_c = warning === null || warning === void 0 ? void 0 : warning.settings) === null || _c === void 0 ? void 0 : _c.is_pre_ai);
        });
        var showConfirmation = selectedReadingTypeIds.length > 1 || hasAutoAi || isAutoPreAi;
        if (showConfirmation) {
            var textConfirmation = '';
            if (comments.length > 0) {
                textConfirmation +=
                    'These alarm levels were set upon request, are you sure you want to confirm the action? </br>';
            }
            if (selectedReadingTypeIds.length > 1) {
                var axesLabel = selectedReadingTypeIds
                    .map(function (selectedReadingTypeId) { var _a; return (_a = READING_TYPES[selectedReadingTypeId]) === null || _a === void 0 ? void 0 : _a.axisShort; })
                    .join(', ');
                textConfirmation += "Are you sure you want to set the same levels for the ".concat(axesLabel, " axes? </br>");
            }
            if (hasAutoAi || isAutoPreAi) {
                textConfirmation += "This reading type has settings for creation ".concat(hasAutoAi ? 'Auto AI' : '', " ").concat(hasAutoAi && isAutoPreAi ? ' and ' : '', " ").concat(isAutoPreAi ? 'Auto Pre-AI' : '', ", are you sure you want to change the alarm levels?");
            }
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: textConfirmation,
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    submitData();
                },
            });
        }
        submitData();
    };
    return (_jsxs(_Fragment, { children: [(triggerEachReading || !!comments.length) && (_jsxs(Row, { className: "g-0 mb-2", children: [triggerEachReading && (_jsxs(Col, { children: [_jsx(Badge, { className: "badge-circle", color: "danger", children: _jsx(FaInfo, {}) }), _jsx("span", { className: "fw-bold align-bottom ms-2", children: "This option will be trigger each reading for check alarm" })] })), !!comments.length && (_jsxs(Col, { children: [_jsx(Badge, { className: "badge-circle", color: "warning", children: _jsx(FaExclamation, {}) }), _jsx("span", { className: "fw-bold align-bottom ms-2", children: "This equipment has an active alert notes for alert levels" })] }))] })), hasAutoAi && _jsx(LossPacketsAlert, { lossPacketsPercent: +packetLossPercent }), _jsxs(Row, { children: [_jsxs(Col, { md: 6, children: [_jsx(Chart, { customerLevels: customerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, chartIdentifiers: chartIdentifiers, selectedReadingTypeIds: selectedReadingTypeIds, setSelectedReadingTypeIds: onSelectReadingType, alertConditions: alertLevels, shouldRefresh: shouldRefreshChart, selectedInstallationPointId: selectedInstallationPointId }), readingTypeId !== READING_TYPE_TACHOMETER && !isAdapter && (_jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forAllSensors, onChange: function () { return setForAllSensors(!forAllSensors); } }), _jsx("span", { className: "fw-bold", children: "Set Alarm for all components on equipment" })] }) }) })), readingTypeId !== READING_TYPE_TACHOMETER &&
                                Object.keys(overlayInstallationPoints).length !== 0 && (_jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forOverlaid, onChange: function () { return setForOverlaid(!forOverlaid); } }), _jsx("span", { className: "fw-bold", children: "Set Alarm for all overlaid components" })] }) }) }))] }), _jsxs(Col, { md: 6, children: [!!validationErrors.length && (_jsx("ul", { className: "validation-errors", children: validationErrors.map(function (error, i) { return (_jsx("li", { children: error }, i)); }) })), _jsxs("form", { id: "alertLevelForm", onSubmit: handleSubmit, children: [_jsx("div", { className: "alert-levels", children: alertLevels.map(function (levels, index) {
                                            var _a, _b, _c, _d;
                                            var caution = levels.caution, warning = levels.warning;
                                            var hasConditionIds = ((_a = caution === null || caution === void 0 ? void 0 : caution.settings) === null || _a === void 0 ? void 0 : _a.alert_condition_id) && ((_b = warning === null || warning === void 0 ? void 0 : warning.settings) === null || _b === void 0 ? void 0 : _b.alert_condition_id);
                                            return (_jsxs("section", { className: "alert-levels__section alert-level-section", children: [_jsxs("div", { className: "alert-level-section__headlines block-grid", children: [_jsx("span", { children: "Alert type" }), _jsx("span", { children: "Value" }), _jsx("span", { children: "Action" })] }), _jsx(AlertLevelBlock, { levelIndex: index, alertCondition: caution, level: "caution", customerLevel: (_c = customerLevels === null || customerLevels === void 0 ? void 0 : customerLevels.caution) !== null && _c !== void 0 ? _c : null, setIsVisibleCustomerLevels: setIsVisibleCustomerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, onChange: function () {
                                                            var args = [];
                                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                                args[_i] = arguments[_i];
                                                            }
                                                            return changeNewAlertCondition.apply(void 0, __spreadArray([index, 'caution'], args, false));
                                                        }, onDeleteAlertCondition: function () {
                                                            var args = [];
                                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                                args[_i] = arguments[_i];
                                                            }
                                                            return deleteAlertCondition.apply(void 0, __spreadArray([index], args, false));
                                                        }, isAdapter: isAdapter }), _jsx(AlertLevelBlock, { levelIndex: index, alertCondition: warning, level: "warning", customerLevel: (_d = customerLevels === null || customerLevels === void 0 ? void 0 : customerLevels.warning) !== null && _d !== void 0 ? _d : null, setIsVisibleCustomerLevels: setIsVisibleCustomerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, onChange: function () {
                                                            var args = [];
                                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                                args[_i] = arguments[_i];
                                                            }
                                                            return changeNewAlertCondition.apply(void 0, __spreadArray([index, 'warning'], args, false));
                                                        }, onDeleteAlertCondition: function () {
                                                            var args = [];
                                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                                args[_i] = arguments[_i];
                                                            }
                                                            return deleteAlertCondition.apply(void 0, __spreadArray([index], args, false));
                                                        }, isAdapter: isAdapter }), index === 1 && !hasConditionIds && (_jsx(Button, { className: "btn-delete-pair", color: "danger", onClick: removeLevelsSecondPairOfLevels, children: _jsx(FaTrashAlt, {}) }))] }, index));
                                        }) }), canAddMoreAlertLevels && (_jsx(Button, { outline: true, color: "primary", className: "mt-2 btn-add-second-pair", onClick: addAlertLevel, children: "Add second pair" }))] })] })] })] }));
};

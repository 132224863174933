var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import Loader from '../../../../../shared/components/Loader';
import { ManageAnalystFilterModal } from '../../../../modals/ManageAnalystFilterModal';
import { useAnalystFiltersQuery, } from '../../../../services/analystFilters/useAnalystFiltersQuery';
import { useCustomersQuery } from '../../../../services/useCustomersQuery';
import { useLeadAnalystQuery } from '../../../../services/useLeadAnalystQuery';
import { selectIsVisibleManageAnalystFilter } from '../../../../store/ManageAnalystFilterStore';
import { selectSelectedCustomers, useSelectedCustomersActions, } from '../../../../store/SelectedCustomersStore';
import { selectSelectedLeadAnalyst, useSelectedLeadAnalystActions, } from '../../../../store/SelectedLeadAnalystStore';
import { selectSelectedLocations, useSelectedLocationsActions, } from '../../../../store/SelectedLocationsStore';
import { AnalystFiltersSelectModal } from './components/AnalystFiltersSelectModal';
export var AnalystFiltersSelect = memo(function () {
    var trackEvent = useMatomo().trackEvent;
    var _a = useState(false), isVisibleModal = _a[0], setIsVisibleModal = _a[1];
    var analystFiltersData = useAnalystFiltersQuery().data;
    var analystFiltersList = analystFiltersData.list;
    var customersData = useCustomersQuery().data;
    var customers = customersData.customers;
    var _b = useLeadAnalystQuery({ customers: customers }), leadAnalystData = _b.data, leadAnalystIsLoading = _b.isLoading, leadAnalystIsFetching = _b.isFetching;
    var leadAnalyst = leadAnalystData.data;
    var setSelectedLeadAnalyst = useSelectedLeadAnalystActions().setSelectedLeadAnalyst;
    var setSelectedLocations = useSelectedLocationsActions().setSelectedLocations;
    var setSelectedCustomers = useSelectedCustomersActions().setSelectedCustomers;
    var selectedLeadAnalyst = selectSelectedLeadAnalyst();
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var isVisible = selectIsVisibleManageAnalystFilter();
    var onChangeHandler = useCallback(function (_a) {
        var id = _a.id, name = _a.name;
        setIsVisibleModal(false);
        setSelectedLeadAnalyst([id]);
        trackEvent({ category: 'Base', action: 'Analyst filter', name: name, href: window.location.href });
        if (selectedCustomers.length) {
            setSelectedCustomers([]);
        }
        if (selectedLocations.length) {
            setSelectedLocations([]);
        }
    }, [leadAnalyst, analystFiltersList, selectedCustomers, selectedLocations]);
    var activeFilter = useMemo(function () { return __spreadArray(__spreadArray([], leadAnalyst, true), analystFiltersList, true).find(function (_a) {
        var id = _a.id;
        return id === selectedLeadAnalyst[0];
    }); }, [leadAnalyst, analystFiltersList, selectedLeadAnalyst]);
    return (_jsx("div", { className: "header-select", children: leadAnalystIsLoading || leadAnalystIsFetching ? (_jsx("span", { children: _jsx(Loader, { variant: "loader-xs" }) })) : (_jsxs(_Fragment, { children: [_jsxs("span", { onClick: function () { return setIsVisibleModal(true); }, children: [activeFilter ? activeFilter.label : 'Select filter', _jsx(FaAngleDown, {})] }), isVisibleModal && (_jsx(AnalystFiltersSelectModal, { onClose: function () { return setIsVisibleModal(false); }, leadAnalystData: leadAnalystData, analystFiltersData: analystFiltersData, onChangeHandler: onChangeHandler })), isVisible && _jsx(ManageAnalystFilterModal, {})] })) }));
});
AnalystFiltersSelect.displayName = 'AnalystFiltersSelect';

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useContext } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import TopLogoWithoutFlagForLightMode from '@assets/images/top-logo-without-flag-for-light-mode.svg';
import TopLogoWithoutFlag from '@assets/images/top-logo-without-flag.svg';
import Ukraine from '@assets/images/ukraine.png';
import { SYSTEM_TYPE_AMAZON, SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import LinkWithPrefix from '../../../../shared/components/LinkWithPrefix';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { getIsBlockedActionOnAIPreAIPage } from '../../../../shared/helper/commonHelper';
import { selectTheme } from '../../../../shared/store/global/useGlobalStore';
export var Logo = memo(function () {
    var pathname = useLocation().pathname;
    var _a = useContext(SystemTypeContext), selectedSystemType = _a[0], setSelectedSystemType = _a[1];
    var isBlockedChangeSystem = getIsBlockedActionOnAIPreAIPage(pathname);
    var theme = selectTheme();
    return (_jsxs("div", { className: "d-flex", children: [_jsxs("div", { className: "d-flex flex-column align-items-end", children: [_jsx(LinkWithPrefix, { to: "/", children: _jsx("img", { className: "waites-title", src: theme === 'dark' ? TopLogoWithoutFlag : TopLogoWithoutFlagForLightMode, alt: "logo" }) }), _jsxs("div", { className: "system-change-dropdown", children: [_jsxs("div", { className: "d-flex cursor-pointer", children: [_jsx("span", { children: selectedSystemType === SYSTEM_TYPE_AMAZON ? SYSTEM_TYPE_AMAZON : 'Global' }), _jsx(FaAngleLeft, {})] }), _jsxs("div", { className: "dropdown-menu-content", children: [_jsx("span", { id: "isBlockedChangeSystem", onClick: function () {
                                            if (isBlockedChangeSystem) {
                                                return;
                                            }
                                            setSelectedSystemType(selectedSystemType === SYSTEM_TYPE_REMASTERED ? SYSTEM_TYPE_AMAZON : SYSTEM_TYPE_REMASTERED);
                                        }, className: isBlockedChangeSystem ? 'disabled' : '', children: selectedSystemType === SYSTEM_TYPE_AMAZON ? 'Global' : SYSTEM_TYPE_AMAZON }), isBlockedChangeSystem ? (_jsx(UncontrolledTooltip, { placement: "bottom", target: 'isBlockedChangeSystem', children: "It is forbidden to switch the system on this page" })) : (_jsx(_Fragment, {}))] })] })] }), _jsx("a", { href: "https://ukraine.waites.net/", className: "d-flex align-items-center", target: "_blank", rel: "noopener noreferrer", children: _jsx("img", { className: "flag-logo", src: Ukraine, alt: "logo" }) })] }));
});
Logo.displayName = 'Logo';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { memo, useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Input, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import DataTable from '../../../shared/components/shared/Table/Table';
import Filters from '../../../shared/components/watchList/Filter';
import { config as baseConfig } from '../../../shared/config/watchList/dataTableConfig';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { exportToCsv, onChangeDateRangeFilter, onViewInstallationPoint, } from '../../../shared/features/shared';
import { onChangeState } from '../../../shared/features/watchList';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../../shared/helper/table';
import User from '../../../shared/helper/user';
import { useResetDataTableEffect } from '../../../shared/hooks/useResetDataTableEffect';
import EditingWatchListModal from '../../../shared/modals/watchList/Editing';
import RemovingWatchListModal from '../../../shared/modals/watchList/Removing';
import '../../../shared/styles/watchList/watchList.scss';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../store/SelectedLocationsStore';
var initialState = function (onViewInstallationPoint) {
    var config = baseConfig(onViewInstallationPoint, 'Delete', function () { return +User.can('manageWatchList'); });
    return {
        selectedState: '',
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'user_name',
                    title: 'User',
                    sortable: true,
                },
                {
                    name: 'customer_name',
                    title: 'Customer',
                    sortable: true,
                },
                {
                    name: 'facility_name',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'location_name',
                    title: 'Location',
                    sortable: true,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                }
            ], config.columns, true), sort: [
                {
                    field: 'time_create',
                    direction: 'desc',
                },
            ], pagination: {
                page: 1,
                pageSize: 20,
                total: 0,
            }, search: '', data: [], loader: true, filters: function (callbacks, filtersData) {
                var _a;
                return (_jsx(Filters, { dateRange: filtersData.dateRange, onChangeDateRange: callbacks.onChangeDateRange, onChangeState: callbacks.onChangeState, isFilterClick: filtersData.isFilterClick, children: _jsxs(Input, { type: "select", name: "select", className: "select-sm users-select me-2", onChange: function (ev) { return callbacks.setUser(ev.target.value); }, children: [_jsx("option", { value: "", children: "All users" }), ((_a = filtersData.users) === null || _a === void 0 ? void 0 : _a.length) ? (filtersData.users.map(function (user) { return (_jsx("option", { value: user.user_id, children: user.user_name }, "users-watchlist-filter-".concat(user.user_id))); })) : (_jsx(_Fragment, {}))] }) }));
            }, filtersData: {
                isFilterClick: false,
                dateRange: [],
            } }),
    };
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations, userId) {
    var _a, _b;
    var config = state.config, selectedState = state.selectedState;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { loader: true }),
        },
    });
    Endpoints.remastered
        .getUsersWatchList({
        query: {
            selected_state: selectedState,
            selected_customer_ids: selectedCustomers,
            selected_location_ids: selectedLocations,
            pagination: config.pagination,
            sort: config.sort,
            user_id: userId,
            search: config.search,
            date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                : '',
            date_to: ((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[1])
                ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                : '',
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }), filtersData: __assign(__assign({}, config.filtersData), { isFilterClick: true, users: resp.users }) }),
                },
            });
        }
    });
};
var WatchListUsersPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Users Watch List');
    if (!User.can('viewWatchList')) {
        var navigate = useNavigate();
        navigate('/401');
    }
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useReducer(reducer, initialState(function (installationPointId) { return onViewInstallationPoint(installationPointId, selectedSystemType); })), state = _c[0], dispatch = _c[1];
    var _d = useState(null), user = _d[0], setUser = _d[1];
    var _e = useState(null), removeItemId = _e[0], setRemoveItemId = _e[1];
    var _f = useState(null), editItemId = _f[0], setEditItemId = _f[1];
    var _g = useState(''), editItemDesc = _g[0], setEditItemDesc = _g[1];
    var config = state.config, selectedState = state.selectedState;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    useResetDataTableEffect([selectedCustomers, selectedLocations, config.filtersData.dateRange], state, dispatch);
    useEffect(function () {
        fetch(state, dispatch, selectedCustomers, selectedLocations, user);
    }, [refreshFlag, selectedCustomers, selectedLocations, user, selectedState, config.filtersData.dateRange]);
    return (_jsxs(React.Fragment, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsxs("h3", { className: "page-title", children: ["Users WatchList", config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                        return exportToCsv(Endpoints.remastered.getUsersWatchListCsv, 'users-watchlist', {
                                            query: {
                                                selected_state: selectedState,
                                                selected_customer_ids: selectedCustomers,
                                                selected_location_ids: selectedLocations,
                                                sort: config.sort,
                                                user_id: user,
                                                search: config.search,
                                                date_from: config.filtersData.dateRange[0]
                                                    ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                    : '',
                                                date_to: config.filtersData.dateRange[1]
                                                    ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                    : '',
                                            },
                                            isBlob: true,
                                        });
                                    }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }), _jsx("div", { className: "table_wrap user-watchlist-table align-pagination-section align-user-filter-section", children: _jsx(DataTable, { config: config, onPageChange: function (page) {
                                    return onPageChange(page, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedCustomers, selectedLocations, user);
                                    });
                                }, onPageSizeChange: function (pageSize) {
                                    return onPageSizeChange(pageSize, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedCustomers, selectedLocations, user);
                                    });
                                }, onSort: function (column_name, ev) {
                                    return onSort(column_name, state, dispatch, function () { return fetch(state, dispatch, selectedCustomers, selectedLocations, user); }, ev);
                                }, callbacks: {
                                    onChangeState: function (selectedState) { return onChangeState(selectedState, dispatch); },
                                    deleteWatchListItem: function (id) { return setRemoveItemId(id); },
                                    editWatchListItem: function (id, desc) {
                                        setEditItemId(id);
                                        setEditItemDesc(desc);
                                    },
                                    setUser: setUser,
                                    onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                                }, onSearch: function (search) {
                                    return onSearch(search, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedCustomers, selectedLocations, user);
                                    });
                                } }) })] }) }), _jsx(RemovingWatchListModal, { endpoint: Endpoints.remastered.softRemoveWatchListItem, onCancel: function () { return setRemoveItemId(null); }, visible: !!removeItemId, callback: function () { return fetch(state, dispatch, selectedCustomers, selectedLocations, user); }, id: removeItemId }), _jsx(EditingWatchListModal, { description: editItemDesc, endpoint: Endpoints.remastered.editWatchListItem, onCancel: function () { return setEditItemId(null); }, visible: !!editItemId, callback: function () { return fetch(state, dispatch, selectedCustomers, selectedLocations, user); }, id: editItemId })] }));
};
export default memo(WatchListUsersPage);

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import Loader from '../../../../../shared/components/Loader';
import { AreaSelectModal } from './components/AreaSelectModal';
export var AreaSelect = memo(function (_a) {
    var isLoading = _a.isLoading, areaList = _a.areaList, selectedArea = _a.selectedArea, onChangeArea = _a.onChangeArea;
    var _b = useState(false), isVisibleModal = _b[0], setIsVisibleModal = _b[1];
    return (_jsx("div", { className: "header-select", children: isLoading ? (_jsx("span", { children: _jsx(Loader, { variant: "loader-xs" }) })) : (_jsxs(_Fragment, { children: [_jsxs("span", { onClick: function () { return setIsVisibleModal(true); }, children: [selectedArea !== null && selectedArea !== void 0 ? selectedArea : 'Area - ' + areaList.length, _jsx(FaAngleDown, {})] }), isVisibleModal ? (_jsx(AreaSelectModal, { onClose: function () { return setIsVisibleModal(false); }, areaList: areaList, onChangeArea: onChangeArea, selectedArea: selectedArea })) : (_jsx(_Fragment, {}))] })) }));
});
AreaSelect.displayName = 'AreaSelect';

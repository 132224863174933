import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useClickAway } from '@uidotdev/usehooks';
import React, { memo } from 'react';
import { FaTimes } from 'react-icons/fa';
import '../../../../../../../shared/styles/SelectsGroup/components/index.scss';
import { useAnalystFiltersQuery, } from '../../../../../../services/analystFilters/useAnalystFiltersQuery';
import { useLocationsTreeQuery } from '../../../../../../services/useLocationsTreeQuery';
import { selectSelectedCustomers } from '../../../../../../store/SelectedCustomersStore';
import { selectSelectedLeadAnalyst } from '../../../../../../store/SelectedLeadAnalystStore';
import { selectSelectedLocations } from '../../../../../../store/SelectedLocationsStore';
import { LocationsSelectTree } from '../LocationsSelectTree';
export var LocationsSelectModal = memo(function (_a) {
    var onChangeHandler = _a.onChangeHandler, onClose = _a.onClose;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var ref = useClickAway(onClose);
    var selectedLeadAnalyst = selectSelectedLeadAnalyst();
    var _b = useLocationsTreeQuery({
        selectedCustomers: selectedCustomers,
    }), locationsTreeData = _b.data, locationsTreeIsLoading = _b.isLoading, locationsTreeIsFetching = _b.isFetching;
    var analystFiltersData = useAnalystFiltersQuery().data;
    var analystFiltersList = analystFiltersData.list;
    var analystSelectedLocation = (analystFiltersList.find(function (_a) {
        var id = _a.id;
        return id === selectedLeadAnalyst[0];
    }) || {}).selectedLocations;
    return (_jsxs("dialog", { ref: ref, open: true, className: "location-dialog", children: [_jsxs("header", { children: [_jsx("h3", { children: "Locations" }), _jsx(FaTimes, { onClick: onClose })] }), _jsx("div", { className: "content", children: _jsx(LocationsSelectTree, { locationsTreeData: locationsTreeData, locationsTreeIsLoading: locationsTreeIsLoading, locationsTreeIsFetching: locationsTreeIsFetching, onChangeHandler: onChangeHandler, selectedLocations: selectedLocations, filteredLocations: analystSelectedLocation }) })] }));
});
LocationsSelectModal.displayName = 'LocationsSelectModal';

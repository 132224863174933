var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Api from '../../api';
export default {
    getAlertConditionLog: function (installationPointId, readingTypeId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/alert-conditions/get-log/".concat(installationPointId, "/").concat(readingTypeId), options);
    },
    getAlertConditionComments: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/alert-conditions/get-comments', options);
    },
    getCustomerAlertConditions: function (installationPointId, readingTypeId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/alert-conditions/get-customers-data/".concat(installationPointId, "/").concat(readingTypeId), options);
    },
    getAlertConditionCommentsBatch: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/alert-conditions/get-comments-batch/".concat(installationPointId), options);
    },
    deleteAlertConditionComment: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.post("all-analytics/alert-conditions/delete-comment/".concat(id), options);
    },
    /** @deprecated */
    saveAlertConditions: function (installationPointId, readingTypeId, data) {
        if (data === void 0) { data = {}; }
        return Api.post("all-analytics/alert-conditions/save/".concat(installationPointId, "/").concat(readingTypeId), data);
    },
    saveAlertConditionsComment: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/alert-conditions/save-comment', data);
    },
    createFftAlertCondition: function (installationPointId, data) {
        if (data === void 0) { data = {}; }
        return Api.post("all-analytics/alert-conditions/create-fft/".concat(installationPointId), data);
    },
    updateFftAlertCondition: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/alert-conditions/update-fft', data);
    },
    createOnceAlertLevel: function (payload, options) {
        var installationPointId = payload.installationPointId, readingTypeId = payload.readingTypeId, levels = payload.levels, overlayInstallationPoints = payload.overlayInstallationPoints, setForAllEquipment = payload.setForAllEquipment, setForAllComponents = payload.setForAllComponents;
        return Api.post("all-analytics/alert-conditions/create/".concat(installationPointId, "/").concat(readingTypeId), __assign(__assign({}, levels), { overlayInstallationPoints: overlayInstallationPoints, setForAllEquipment: setForAllEquipment, setForAllComponents: setForAllComponents }), options);
    },
    updateOnceAlertLevel: function (payload, options) {
        var installationPointId = payload.installationPointId, readingTypeId = payload.readingTypeId, levels = payload.levels, overlayInstallationPoints = payload.overlayInstallationPoints, setForAllEquipment = payload.setForAllEquipment, setForAllComponents = payload.setForAllComponents;
        return Api.put("all-analytics/alert-conditions/update/".concat(installationPointId, "/").concat(readingTypeId), __assign(__assign({}, levels), { overlayInstallationPoints: overlayInstallationPoints, setForAllEquipment: setForAllEquipment, setForAllComponents: setForAllComponents }), options);
    },
    deleteOnceAlertLevel: function (_a, options) {
        var alertConditionId = _a.alertConditionId, installationPointId = _a.installationPointId, readingTypeId = _a.readingTypeId;
        return Api.post("all-analytics/alert-conditions/delete/".concat(installationPointId), {
            ids: [alertConditionId],
            reading_type_id: readingTypeId,
        }, options);
    },
    getAlertLevels: function (installationPointId, readingTypeId) {
        return Api.get("all-analytics/alert-conditions/".concat(installationPointId, "/").concat(readingTypeId));
    },
};

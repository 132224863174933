import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useClickAway, useDebounce } from '@uidotdev/usehooks';
import React, { memo, useEffect, useState } from 'react';
import { FaEdit, FaSearch, FaTimes, FaTrash } from 'react-icons/fa';
import { Input, Label } from 'reactstrap';
import { useConfirmModalActions } from '../../../../../../../shared/store/global/useConfirmModalStore';
import '../../../../../../../shared/styles/SelectsGroup/components/index.scss';
import { useDeleteAnalystFilterMutation } from '../../../../../../services/analystFilters/useDeleteAnalystFilterMutation';
import { useManageAnalystFilterActions } from '../../../../../../store/ManageAnalystFilterStore';
import { selectSelectedLeadAnalyst } from '../../../../../../store/SelectedLeadAnalystStore';
import './index.scss';
export var AnalystFiltersSelectModal = memo(function (_a) {
    var leadAnalystData = _a.leadAnalystData, analystFiltersData = _a.analystFiltersData, onChangeHandler = _a.onChangeHandler, onClose = _a.onClose;
    var _b = useManageAnalystFilterActions(), setIsVisible = _b.setIsVisible, setId = _b.setId, setSelectedCustomers = _b.setSelectedCustomers, setSelectedLocations = _b.setSelectedLocations, setFilterName = _b.setFilterName;
    var selectedLeadAnalyst = selectSelectedLeadAnalyst();
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var ref = useClickAway(onClose);
    var leadAnalyst = leadAnalystData.data;
    var analystFiltersListOrigin = analystFiltersData.list;
    var _c = useState(leadAnalyst), leadAnalystList = _c[0], setLeadAnalystList = _c[1];
    var _d = useState(analystFiltersListOrigin), analystFiltersList = _d[0], setAnalystFiltersList = _d[1];
    var _e = useState(''), searchTerm = _e[0], setSearchTerm = _e[1];
    var debouncedSearchTerm = useDebounce(searchTerm, 100);
    var handleChange = function (e) {
        setSearchTerm(e.target.value);
    };
    useEffect(function () {
        setAnalystFiltersList(analystFiltersListOrigin);
    }, [analystFiltersListOrigin]);
    useEffect(function () {
        setAnalystFiltersList(analystFiltersListOrigin.filter(function (_a) {
            var label = _a.label;
            return label.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
        }));
        setLeadAnalystList(leadAnalyst.filter(function (_a) {
            var label = _a.label;
            return label.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
        }));
    }, [debouncedSearchTerm]);
    var _f = useDeleteAnalystFilterMutation(), deleteAnalystFilterMutate = _f.mutate, deleteAnalystFilterIsLoading = _f.isPending;
    var onEdit = function (e, id, label, selectedCustomers, selectedLocations) {
        e.stopPropagation();
        setIsVisible(true);
        setFilterName(label);
        setSelectedCustomers(selectedCustomers);
        setSelectedLocations(selectedLocations);
        setId(id);
    };
    var onDelete = function (e, id) {
        e.stopPropagation();
        if (deleteAnalystFilterIsLoading) {
            return;
        }
        setConfirmData({
            isVisible: true,
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                deleteAnalystFilterMutate({ id: id });
            },
        });
    };
    return (_jsxs("dialog", { ref: ref, open: true, className: "analyst-filters-select-modal", children: [_jsxs("header", { children: [_jsx("h3", { children: "Select filter" }), _jsx(FaTimes, { onClick: onClose })] }), _jsxs("div", { className: "content", children: [_jsx("search", { children: _jsxs(Label, { for: "analyst-select-search", children: [_jsx(FaSearch, {}), _jsx(Input, { autoFocus: true, autoComplete: "off", type: "search", id: "analyst-select-search", placeholder: "Search in filter", onChange: handleChange })] }) }), _jsxs("section", { children: [!!leadAnalystList.length && (_jsxs(_Fragment, { children: [_jsx("h4", { children: "Lead analyst" }), leadAnalystList.map(function (_a) {
                                        var label = _a.label, id = _a.id;
                                        return (_jsx("span", { onClick: function () { return onChangeHandler({ name: label, id: id }); }, className: "radio-type ".concat(selectedLeadAnalyst[0] === id ? 'active' : ''), children: label }, id));
                                    })] })), !!analystFiltersList.length && (_jsxs(_Fragment, { children: [_jsx("h4", { className: "mt-2", children: "Analyst filters" }), analystFiltersList.map(function (_a) {
                                        var label = _a.label, id = _a.id, selectedCustomers = _a.selectedCustomers, selectedLocations = _a.selectedLocations;
                                        return (_jsxs("span", { onClick: function () { return onChangeHandler({ name: label, id: id }); }, className: "radio-type ".concat(selectedLeadAnalyst[0] === id ? 'active' : ''), children: [label, _jsx(FaEdit, { className: "analyst-filter analyst-filter-edit", onClick: function (e) { return onEdit(e, id, label, selectedCustomers, selectedLocations); } }), _jsx(FaTrash, { className: "analyst-filter analyst-filter-delete", onClick: function (e) { return onDelete(e, id); } })] }, id));
                                    })] }))] })] }), _jsxs("footer", { children: [_jsx("span", { onClick: function () { return setIsVisible(true); }, children: "Add filter" }), _jsx("span", { onClick: function () { return onChangeHandler({ id: 0, name: '' }); }, children: "Reset" })] })] }));
});
AnalystFiltersSelectModal.displayName = 'AnalystFiltersSelectModal';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { useSelectedCustomersActions } from '../../store/SelectedCustomersStore';
import { useSelectedLeadAnalystActions } from '../../store/SelectedLeadAnalystStore';
import { useSelectedLocationsActions } from '../../store/SelectedLocationsStore';
import { analystFiltersKey } from './useAnalystFiltersQuery';
var deleteAnalystFilterFn = function (id) {
    return Api.del('all-analytics/analyst-filter', {
        id: id,
    });
};
export var useDeleteAnalystFilterMutation = function () {
    var setSelectedLeadAnalyst = useSelectedLeadAnalystActions().setSelectedLeadAnalyst;
    var setSelectedLocations = useSelectedLocationsActions().setSelectedLocations;
    var setSelectedCustomers = useSelectedCustomersActions().setSelectedCustomers;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var id = _a.id;
            return deleteAnalystFilterFn(id);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: analystFiltersKey() });
            setSelectedLeadAnalyst([]);
            setSelectedLocations([]);
            setSelectedCustomers([]);
        },
    });
};

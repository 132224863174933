import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import { SelectsGroup as SelectsGroupAmazon } from '../../../amazon/components/SelectsGroup';
import { SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { SelectsGroup as SelectsGroupRemastered } from '../../../remastered/components/SelectsGroup';
import LinkWithPrefix from '../../../shared/components/LinkWithPrefix';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { InstallationPointStore } from '../../../shared/store/charts/chartsContent/InstallationPointStore';
import { LeftEquipmentListStore } from '../../../shared/store/charts/leftEquipmentList/LeftEquipmentListStore';
import { Logo } from './components/Logo';
import Profile from './components/Profile';
import ProfileMenu from './components/ProfileMenu';
import './index.scss';
export var Header = function (_a) {
    var auth = _a.auth, layoutDispatch = _a.layoutDispatch, _b = _a.selectedFacility, selectedFacility = _b === void 0 ? null : _b, _c = _a.selectedZone, selectedZone = _c === void 0 ? null : _c, _d = _a.selectedArea, selectedArea = _d === void 0 ? null : _d;
    var resetFilters = LeftEquipmentListStore(function (state) { return state.resetFilters; });
    var setStatesInstallationPointStore = InstallationPointStore(function (state) { return state.setStates; });
    var pathname = useLocation().pathname;
    var isChartsDashboardPage = pathname === '/dashboard' || pathname === '/amazon/dashboard';
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _e = useState(false), isShowProfileMenu = _e[0], setIsShowProfileMenu = _e[1];
    return (_jsxs(Navbar, { className: "header-navbar ".concat(selectedSystemType === SYSTEM_TYPE_REMASTERED ? '' : 'amazon-header-navbar'), expand: true, children: [_jsxs("div", { className: "d-flex align-items-center gap-4", children: [_jsx("div", { className: "wrapper-logo", children: _jsx(Logo, {}) }), _jsxs("div", { className: "headerButtons", children: [_jsx(LinkWithPrefix, { className: "headerButtonDefault ".concat(!isChartsDashboardPage && 'active'), to: '/', onClick: function () {
                                    setStatesInstallationPointStore({ overlayInstallationPoints: [] });
                                    resetFilters();
                                }, children: "Overview" }), _jsx(LinkWithPrefix, { className: "headerButtonDefault ".concat(isChartsDashboardPage && 'active'), to: '/dashboard', onClick: function () {
                                    setStatesInstallationPointStore({ overlayInstallationPoints: [] });
                                    resetFilters();
                                }, children: "Charts" })] }), selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(SelectsGroupRemastered, {})) : (_jsx(SelectsGroupAmazon, { selectedFacility: selectedFacility, selectedZone: selectedZone, selectedArea: selectedArea, layoutDispatch: layoutDispatch }))] }), _jsx("div", { className: "wrapper-header-menu", children: _jsx(Profile, { isShowProfileMenu: isShowProfileMenu, setIsShowProfileMenu: setIsShowProfileMenu, children: _jsx(ProfileMenu, { layoutDispatch: layoutDispatch, isShowProfileMenu: isShowProfileMenu, auth: auth }) }) })] }));
};
Header.displayName = 'Header';

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import Loader from '../../../../../shared/components/Loader';
import { useAnalystFiltersQuery, } from '../../../../services/analystFilters/useAnalystFiltersQuery';
import { useCustomersQuery } from '../../../../services/useCustomersQuery';
import { useLeadAnalystQuery } from '../../../../services/useLeadAnalystQuery';
import { selectSelectedCustomers, useSelectedCustomersActions, } from '../../../../store/SelectedCustomersStore';
import { selectSelectedLeadAnalyst } from '../../../../store/SelectedLeadAnalystStore';
import { useSelectedLocationsActions } from '../../../../store/SelectedLocationsStore';
import { CustomersSelectModal } from './components/CustomersSelectModal';
export var CustomersSelect = memo(function () {
    var trackEvent = useMatomo().trackEvent;
    var _a = useState(false), isVisibleModal = _a[0], setIsVisibleModal = _a[1];
    var _b = useCustomersQuery(), customersData = _b.data, customersIsLoading = _b.isLoading, customersIsFetching = _b.isFetching;
    var customers = customersData.customers;
    var leadAnalystData = useLeadAnalystQuery({ customers: customers }).data;
    var leadAnalyst = leadAnalystData.data;
    var analystFiltersData = useAnalystFiltersQuery().data;
    var analystFiltersList = analystFiltersData.list;
    var setSelectedCustomers = useSelectedCustomersActions().setSelectedCustomers;
    var setSelectedLocations = useSelectedLocationsActions().setSelectedLocations;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLeadAnalyst = selectSelectedLeadAnalyst();
    var options = useMemo(function () {
        var _a;
        var customerAnalyticLeads = (leadAnalyst.find(function (_a) {
            var id = _a.id;
            return id === selectedLeadAnalyst[0];
        }) || {}).customerAnalyticLeads;
        var selectedCustomersAnalystFilters = (analystFiltersList.find(function (_a) {
            var id = _a.id;
            return id === selectedLeadAnalyst[0];
        }) || {}).selectedCustomers;
        return {
            customers: ((_a = customerAnalyticLeads !== null && customerAnalyticLeads !== void 0 ? customerAnalyticLeads : (selectedCustomersAnalystFilters
                ? customers.filter(function (_a) {
                    var customer_id = _a.customer_id;
                    return selectedCustomersAnalystFilters.includes(customer_id);
                })
                : customers)) !== null && _a !== void 0 ? _a : []).map(function (customer) { return ({
                name: customer.name,
                customer_id: customer.customer_id,
            }); }),
        };
    }, [customers, leadAnalyst, selectedLeadAnalyst]);
    var onChangeHandler = useCallback(function (items) {
        var selectedCustomerIds = [];
        var customerNames = [];
        items.forEach(function (_a) {
            var id = _a.id, name = _a.name;
            selectedCustomerIds.push(id);
            customerNames.push(name);
        });
        setSelectedLocations([]);
        setSelectedCustomers(selectedCustomerIds);
        trackEvent({
            category: 'Base',
            action: 'Change customers',
            name: customerNames.join(', '),
            href: window.location.href,
        });
    }, []);
    var activeCustomers = useMemo(function () { return customers.filter(function (_a) {
        var customer_id = _a.customer_id;
        return selectedCustomers.includes(customer_id);
    }); }, [customers, selectedCustomers]);
    return (_jsx("div", { className: "header-select", children: customersIsLoading || customersIsFetching ? (_jsx("span", { children: _jsx(Loader, { variant: "loader-xs" }) })) : (_jsxs(_Fragment, { children: [_jsxs("span", { onClick: function () { return setIsVisibleModal(true); }, children: [activeCustomers.length ? 'Customers - ' + activeCustomers.length : 'All customers', _jsx(FaAngleDown, {})] }), isVisibleModal ? (_jsx(CustomersSelectModal, { onClose: function () { return setIsVisibleModal(false); }, customersData: options, onChangeHandler: onChangeHandler })) : (_jsx(_Fragment, {}))] })) }));
});
CustomersSelect.displayName = 'CustomersSelect';

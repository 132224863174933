import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { analystFiltersKey } from './useAnalystFiltersQuery';
var updateAnalystFilterFn = function (id, name, description, customers) {
    return Api.put("all-analytics/analyst-filter/".concat(id), {
        name: name,
        description: description,
        customers: customers,
    });
};
export var useUpdateAnalystFilterMutation = function () {
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var id = _a.id, name = _a.name, description = _a.description, customers = _a.customers;
            return updateAnalystFilterFn(id, name, description, customers);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: analystFiltersKey() });
        },
    });
};

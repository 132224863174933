import { first as _first, get as _get } from 'lodash';
import Endpoints from '../../../../api/endpoints/endpoints';
import areaStorage from '../../../../storage/area';
import facilityStorage from '../../../../storage/facility';
import zoneStorage from '../../../../storage/zone';
export var fetchFacilitiesList = function (setFetchFacility, setFetchZone, setFacilityList, setZones, setAreas, setShowAmazon, setFacilitiesListFacilitiesListStore, setIsLoadingFacility, setIsLoadingZone, setIsLoadingArea) {
    setIsLoadingFacility(true);
    Endpoints.amazon
        .getFacilitiesList()
        .then(function (_a) {
        var _b = _a.list, list = _b === void 0 ? [] : _b;
        if (list.length === 1) {
            setShowAmazon('zone');
            fetchFacilityZoneTree(_get(_first(list), 'facility_id'), function (resp) {
                setFetchFacility(true);
                setFacilityList(list);
                setFacilitiesListFacilitiesListStore(list);
                setZones(resp.zones || []);
            }, setShowAmazon, setFetchZone, setZones, setAreas, setIsLoadingZone, setIsLoadingArea);
            return;
        }
        setFetchFacility(true);
        setFacilityList(list);
        setFacilitiesListFacilitiesListStore(list);
    })
        .finally(function () { return setIsLoadingFacility(false); });
};
export var fetchFacilityZoneTree = function (facilityId, callback, setShowAmazon, setFetchZone, setZones, setAreas, setIsLoadingZone, setIsLoadingArea) {
    setIsLoadingZone(true);
    Endpoints.amazon
        .getFacilityZones(facilityId)
        .then(function (resp) {
        if (resp.zones.length === 1) {
            setShowAmazon('area');
            fetchFacilityAreaTree(facilityId, _first(resp.zones), function (resp) {
                setAreas(resp.areas || []);
            }, setIsLoadingArea);
        }
        setZones(resp.zones || []);
        setFetchZone(true);
        callback(resp);
    })
        .finally(function () { return setIsLoadingZone(false); });
};
export var fetchFacilityAreaTree = function (facilityId, zone, callback, setIsLoadingArea) {
    setIsLoadingArea(true);
    Endpoints.amazon
        .getFacilityArea(facilityId, zone)
        .then(function (resp) {
        callback(resp);
    })
        .finally(function () { return setIsLoadingArea(false); });
};
export var onChangeZone = function (layoutDispatch, name, trackEvent) {
    layoutDispatch({
        type: 'setZone',
        selectedZone: name,
    });
    layoutDispatch({
        type: 'setArea',
        selectedZone: null,
    });
    areaStorage.remove();
    if (!name) {
        zoneStorage.remove();
        return;
    }
    trackEvent({ category: 'Base', action: 'Change zone', name: name, href: window.location.href });
    zoneStorage.set(name);
};
export var onChangeArea = function (layoutDispatch, name, trackEvent) {
    layoutDispatch({
        type: 'setArea',
        selectedArea: name,
    });
    if (!name) {
        areaStorage.remove();
        return;
    }
    trackEvent({ category: 'Base', action: 'Change area', name: name, href: window.location.href });
    areaStorage.set(name);
};
export var onChangeFacility = function (layoutDispatch, name, trackEvent) {
    layoutDispatch({
        type: 'setFacility',
        selectedFacility: name,
    });
    layoutDispatch({
        type: 'setZone',
        selectedZone: null,
    });
    layoutDispatch({
        type: 'setArea',
        selectedZone: null,
    });
    areaStorage.remove();
    zoneStorage.remove();
    if (!name) {
        facilityStorage.remove();
        return;
    }
    trackEvent({ category: 'Base', action: 'Change facility', name: name, href: window.location.href });
    facilityStorage.set(name);
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analyst-filters-select-modal .analyst-filter {
  padding: 6px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  transition: all 0.2s;
}
.analyst-filters-select-modal .analyst-filter-edit {
  color: #2962ff;
  margin-left: auto;
  margin-right: 4px;
}
.analyst-filters-select-modal .analyst-filter-edit:hover {
  background: rgba(41, 98, 255, 0.2509803922);
}
.analyst-filters-select-modal .analyst-filter-delete {
  color: #cc3232;
}
.analyst-filters-select-modal .analyst-filter-delete:hover {
  background: rgba(176, 25, 25, 0.2509803922);
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/SelectsGroup/components/AnalystFiltersSelect/components/AnalystFiltersSelectModal/index.scss"],"names":[],"mappings":"AACC;EACC,YAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;AAAF;AAEC;EACC,cAAA;EACA,iBAAA;EACA,iBAAA;AAAF;AACE;EACC,2CAAA;AACH;AAEC;EACC,cAAA;AAAF;AACE;EACC,2CAAA;AACH","sourcesContent":[".analyst-filters-select-modal {\n\t.analyst-filter {\n\t\tpadding: 6px;\n\t\twidth: 30px;\n\t\theight: 30px;\n\t\tborder-radius: 4px;\n\t\ttransition: all 0.2s;\n\t}\n\t.analyst-filter-edit {\n\t\tcolor: #2962ff;\n\t\tmargin-left: auto;\n\t\tmargin-right: 4px;\n\t\t&:hover {\n\t\t\tbackground: #2962ff40;\n\t\t}\n\t}\n\t.analyst-filter-delete {\n\t\tcolor: #cc3232;\n\t\t&:hover {\n\t\t\tbackground: #b0191940;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

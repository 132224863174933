import { concat as _concat, difference as _difference, intersection as _intersection, union as _union, } from 'lodash';
var getAllChildrenFn = function (item, children, key) {
    if (!(item === null || item === void 0 ? void 0 : item.children.length)) {
        return;
    }
    item.children.forEach(function (el) {
        if (!el.children.length) {
            children.push(el[key]);
        }
        getAllChildrenFn(el, children, key);
    });
};
export var getAllChildrenIds = function (item) {
    var children = [];
    getAllChildrenFn(item, children, 'value');
    return children;
};
export var getAllChildrenLabels = function (item) {
    var children = [];
    getAllChildrenFn(item, children, 'label');
    return children;
};
export var isActive = function (item, selectedLocationsTree) {
    if (!item.children.length) {
        return selectedLocationsTree.includes(item.value);
    }
    return getAllChildrenIds(item).every(function (id) { return selectedLocationsTree.includes(id); });
};
export var isHalfActive = function (item, selectedLocationsTree, filtersLocations) {
    if (!item.children.length || !filtersLocations.length) {
        return false;
    }
    return _intersection(getAllChildrenIds(item), filtersLocations).every(function (id) {
        return selectedLocationsTree.includes(id);
    });
};
export var onChangeLocationsHandler = function (item, selectedLocations, setSelectedLocations, analystSelectedLocation) {
    if (!item) {
        return setSelectedLocations([]);
    }
    if (!item.children.length) {
        if (selectedLocations.includes(item.value)) {
            setSelectedLocations(selectedLocations.filter(function (id) { return id !== item.value; }));
        }
        else {
            setSelectedLocations(_concat(selectedLocations, item.value));
        }
    }
    else {
        var allChildren = (analystSelectedLocation === null || analystSelectedLocation === void 0 ? void 0 : analystSelectedLocation.length)
            ? _intersection(getAllChildrenIds(item), analystSelectedLocation)
            : getAllChildrenIds(item);
        if (allChildren.every(function (id) { return selectedLocations.includes(id); })) {
            setSelectedLocations(_difference(selectedLocations, allChildren));
        }
        else {
            setSelectedLocations(_union(_concat(selectedLocations, allChildren)));
        }
    }
};

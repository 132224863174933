import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import Loader from '../../../../../shared/components/Loader';
import { useAnalystFiltersQuery, } from '../../../../services/analystFilters/useAnalystFiltersQuery';
import { useLocationsTreeQuery } from '../../../../services/useLocationsTreeQuery';
import { selectSelectedCustomers } from '../../../../store/SelectedCustomersStore';
import { selectSelectedLeadAnalyst } from '../../../../store/SelectedLeadAnalystStore';
import { selectSelectedLocations, useSelectedLocationsActions, } from '../../../../store/SelectedLocationsStore';
import { LocationsSelectModal } from './components/LocationsSelectModal';
import { onChangeLocationsHandler } from './features';
export var LocationsSelect = memo(function () {
    var _a = useState(false), isVisibleModal = _a[0], setIsVisibleModal = _a[1];
    var setSelectedLocations = useSelectedLocationsActions().setSelectedLocations;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var _b = useLocationsTreeQuery({
        selectedCustomers: selectedCustomers,
    }), locationsTreeIsLoading = _b.isLoading, locationsTreeIsFetching = _b.isFetching;
    var selectedLeadAnalyst = selectSelectedLeadAnalyst();
    var analystFiltersData = useAnalystFiltersQuery().data;
    var analystFiltersList = analystFiltersData.list;
    var analystSelectedLocation = (analystFiltersList.find(function (_a) {
        var id = _a.id;
        return id === selectedLeadAnalyst[0];
    }) || {}).selectedLocations;
    return (_jsx(_Fragment, { children: !!selectedCustomers.length && location.pathname !== '/customers' && (_jsx("div", { className: "header-select", children: locationsTreeIsLoading || locationsTreeIsFetching ? (_jsx("span", { children: _jsx(Loader, { variant: "loader-xs" }) })) : (_jsxs(_Fragment, { children: [_jsxs("span", { onClick: function () { return setIsVisibleModal(true); }, children: [selectedLocations.length ? 'Locations - ' + selectedLocations.length : 'All locations', _jsx(FaAngleDown, {})] }), isVisibleModal ? (_jsx(LocationsSelectModal, { onClose: function () { return setIsVisibleModal(false); }, onChangeHandler: function (item) {
                            return onChangeLocationsHandler(item, selectedLocations, setSelectedLocations, analystSelectedLocation);
                        } })) : (_jsx(_Fragment, {}))] })) })) }));
});
LocationsSelect.displayName = 'LocationsSelect';

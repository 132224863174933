import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import '../../../shared/styles/SelectsGroup/index.scss';
import { AnalystFiltersSelect } from './components/AnalystFiltersSelect';
import { CustomersSelect } from './components/CustomersSelect';
import { LocationsSelect } from './components/LocationsSelect';
export var SelectsGroup = memo(function () {
    return (_jsxs("div", { className: "gap-3 selects-group", children: [_jsx(AnalystFiltersSelect, {}), _jsx(CustomersSelect, {}), _jsx(LocationsSelect, {})] }));
});
SelectsGroup.displayName = 'SelectsGroup';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, size as _size, uniq as _uniq } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BiExpandAlt } from 'react-icons/bi';
import { FaRegWindowMinimize, FaTimes } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../api/endpoints/endpoints';
import { AXIS_NAMES, AXIS_X, AXIS_Y, AXIS_Z, CHART_GROUPS, METRIC_KEY, READING_TYPES, } from '../../../constants';
import Loader from '../../components/Loader';
import BatchTimestampsCalendar from '../../components/charts/chartsWrapper/components/fftTwf/BatchTimestampsCalendar';
import FftXAxisDropdown from '../../components/charts/chartsWrapper/components/fftTwf/FftAxisFormatter';
import { FrequencyConverter } from '../../components/charts/chartsWrapper/features/fftTwf/frequencyConverter';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getTimezone } from '../../features/shared';
import { getFftReadingsIdsByTimestamps, getUoms } from '../../helper/chart';
import Plot from '../../helper/plotly';
import { calculateReadings, calculateWaterfallSeries, getMaximumTimestamps, getTrendSlice, } from '../../helper/waterfallPlot';
import { useFftBatchQuery } from '../../services/charts/useFftBatchQuery';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure, selectTheme } from '../../store/global/useGlobalStore';
import FrequencyRange from './components/FrequencyRange';
import Trend from './components/Trend/Trend';
import './index.scss';
var getCalculatedTrendFrequency = function (trendFrequency, xMax, frequencyRangeTo) {
    var calculatedTrendFrequency = trendFrequency;
    calculatedTrendFrequency = parseFloat(calculatedTrendFrequency.toString());
    if (Number.isNaN(calculatedTrendFrequency)) {
        calculatedTrendFrequency = '';
    }
    else {
        calculatedTrendFrequency = Math.max(calculatedTrendFrequency, 0);
        calculatedTrendFrequency = Math.min(calculatedTrendFrequency, Math.min(xMax, frequencyRangeTo || Infinity));
        calculatedTrendFrequency = '' + Math.round(calculatedTrendFrequency * 100) / 100;
    }
    return calculatedTrendFrequency;
};
var WaterfallPlotModal = function () {
    var _a, _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var theme = selectTheme();
    var axisColor = theme ? (theme === 'light' ? '#1e222d' : '#f1f1f1') : '#1e222d';
    var bgColor = theme ? (theme === 'light' ? '#f1f1f1' : '#1e222d') : '#f1f1f1';
    var _c = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
        waterfallPlotModal: state.waterfallPlotModal,
        interpolationDisabled: state.interpolationDisabled,
    }); }, shallow), setStatesChartFeaturesStore = _c.setStatesChartFeaturesStore, waterfallPlotModal = _c.waterfallPlotModal, interpolationDisabled = _c.interpolationDisabled;
    var _d = FftChartsStore(function (state) { return ({
        fftData: state.data,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), fftData = _d.fftData, fftTimestampDates = _d.fftTimestampDates;
    var _e = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
        installationPoint: state.installationPoint,
        currentSpeed: state.currentSpeed,
    }); }, shallow), readingTypes = _e.readingTypes, installationPoint = _e.installationPoint, currentSpeed = _e.currentSpeed, equipment = installationPoint.equipment;
    var _f = useState(waterfallPlotModal.chartIdentifier), chartIdentifier = _f[0], setChartIdentifier = _f[1];
    var _g = useState({ from: '', to: '' }), frequencyRange = _g[0], setFrequencyRange = _g[1];
    var _h = useState(false), isTrendVisible = _h[0], setIsTrendVisible = _h[1];
    var _j = useState(0), trendFrequency = _j[0], setTrendFrequency = _j[1];
    var _k = useState(getMaximumTimestamps(installationPoint)), readingsLimit = _k[0], setReadingsLimit = _k[1];
    var _l = useState(waterfallPlotModal.selectedUnits), units = _l[0], setUnits = _l[1];
    var _m = useState(installationPoint), selectedInstallationPoint = _m[0], setSelectedInstallationPoint = _m[1];
    var selectedDate = fftTimestampDates[chartIdentifier];
    var _o = useState(false), fftReadingsTimestampsLoader = _o[0], setFftReadingsTimestampsLoader = _o[1];
    var _p = useState(readingTypes[chartIdentifier]), readingType = _p[0], setReadingType = _p[1];
    var _q = useState(readingType.axisId), selectedAxis = _q[0], setSelectedAxis = _q[1];
    var _r = useState(readingType.groupId), selectedChartGroup = _r[0], setSelectedChartGroup = _r[1];
    var _s = useState(waterfallPlotModal.readingsList || []), holdedReadingsList = _s[0], setHoldedReadingsList = _s[1];
    var _t = useState(waterfallPlotModal.readingsList || []), readingsList = _t[0], setReadingsList = _t[1];
    var _u = useState({
        series: [],
        tickvals: [],
        ticktext: [],
        xMax: 0,
        yMax: 0,
        inititalTimestamps: (waterfallPlotModal.readingsList || []).map(function (readingId) {
            var timestamp = null;
            Object.values(fftData.fftTimestamps[selectedAxis]).map(function (items) {
                items.map(function (item) {
                    if (+item.readingFFT_id === +readingId) {
                        timestamp = item.timestamp;
                    }
                });
            });
            return timestamp;
        }),
    }), chartData = _u[0], setChartData = _u[1];
    var _v = useState(waterfallPlotModal.selectedType ||
        ((waterfallPlotModal === null || waterfallPlotModal === void 0 ? void 0 : waterfallPlotModal.readingsList) && ((_a = waterfallPlotModal === null || waterfallPlotModal === void 0 ? void 0 : waterfallPlotModal.readingsList) === null || _a === void 0 ? void 0 : _a.length) > 0 ? 'selected' : null)), selectedType = _v[0], setSelectedType = _v[1];
    var _w = useState(10), daysForPerDaysType = _w[0], setDaysForPerDaysType = _w[1];
    var _x = useState(false), isExpanded = _x[0], setIsExpanded = _x[1];
    var chartRef = useRef(null);
    useEffect(function () {
        setChartData(__assign(__assign({}, chartData), { series: [] }));
        setReadingsList([]);
        Object.values(readingTypes).forEach(function (readingType, index) {
            if (readingType.axisId === selectedAxis &&
                readingType.groupId === selectedChartGroup &&
                !readingType.isImpactVue) {
                var chartIdentifier_1 = +Object.keys(readingTypes)[index];
                setChartIdentifier(chartIdentifier_1);
                setReadingType(readingTypes[chartIdentifier_1]);
                setHoldedReadingsList(function (prevstate) {
                    return calculateReadings(selectedType, fftData.fftTimestamps, readingType, selectedDate, fftTimestampDates[chartIdentifier_1], daysForPerDaysType, readingsLimit, prevstate);
                });
                return;
            }
        });
    }, [
        selectedAxis,
        selectedChartGroup,
        selectedType,
        daysForPerDaysType,
        readingsLimit,
        selectedInstallationPoint,
    ]);
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var _y = useFftBatchQuery({
        installationPointId: selectedInstallationPoint.id,
        readingsList: readingsList,
        axisID: readingType.axisId,
        highpass: _get(selectedInstallationPoint, 'settings.high_pass', 0),
        interpolationDisabled: +interpolationDisabled,
        selectedFacilityMetric: METRIC_KEY[measure],
        personalSettingMeasure: personalSettingMeasure,
        isHFDvue: +_get(READING_TYPES, [chartIdentifier, 'isImpactVue'], false),
        readingType: selectedChartGroup === CHART_GROUPS.FFT_ACC ? 'acceleration' : 'velocity',
        timezone: getTimezone(installationPoint, selectedSystemType),
    }), fftWaterfallData = _y.data, isPending = _y.isPending;
    useEffect(function () {
        if (_size(fftWaterfallData) === 0 || isPending) {
            return;
        }
        var _a = calculateWaterfallSeries(fftWaterfallData, units, readingsList, readingType, chartIdentifier, selectedInstallationPoint, frequencyRange.from, frequencyRange.to, currentSpeed, measure, personalSettingMeasure, getTimezone(installationPoint, selectedSystemType)), series = _a[0], tickvals = _a[1], ticktext = _a[2], xMax = _a[3], yMax = _a[4], inititalTimestamps = _a[5];
        setChartData({ series: series, tickvals: tickvals, ticktext: ticktext, xMax: xMax, yMax: yMax, inititalTimestamps: inititalTimestamps });
    }, [
        isPending,
        readingsList,
        chartIdentifier,
        selectedInstallationPoint,
        units,
        frequencyRange.from,
        frequencyRange.to,
    ]);
    useEffect(function () {
        if (chartData.series.length === 0 || isPending || !isTrendVisible) {
            return;
        }
        if (+trendFrequency < +frequencyRange.from) {
            setTrendFrequency(+frequencyRange.from);
        }
        if (+trendFrequency > +frequencyRange.to) {
            setTrendFrequency(+frequencyRange.to);
        }
    }, [units, frequencyRange]);
    var onCancel = function () {
        setStatesChartFeaturesStore({
            waterfallPlotModal: __assign(__assign({}, waterfallPlotModal), { visible: false, readingsList: [], selectedType: undefined }),
        });
        document.querySelectorAll('.selected-shape').forEach(function (el) { return el.remove(); });
    };
    var onCollapse = function () {
        setStatesChartFeaturesStore({
            waterfallPlotModal: __assign(__assign({}, waterfallPlotModal), { visible: false, readingsList: holdedReadingsList, selectedType: selectedType }),
        });
    };
    var onApply = function (timestamps) {
        if (timestamps.length === 0) {
            toast.error('Choose at least one timestamp', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            return void 0;
        }
        setSelectedType(selectedType === 'range' ? 'range' : 'selected');
        setHoldedReadingsList(getFftReadingsIdsByTimestamps(Object.values(fftData.fftTimestamps[readingType.axisId]), timestamps));
        queueMicrotask(function () {
            setReadingsList(getFftReadingsIdsByTimestamps(Object.values(fftData.fftTimestamps[readingType.axisId]), timestamps));
        });
    };
    var onChangeInstallationPoint = function (installationPoint) {
        setChartData(__assign(__assign({}, chartData), { series: [] }));
        setReadingsList([]);
        setFftReadingsTimestampsLoader(true);
        var timezone = getTimezone(installationPoint, selectedSystemType);
        Endpoints[selectedSystemType]
            .getFftReadingsTimestamps(installationPoint.id, {
            query: {
                timezone: timezone,
            },
        })
            .then(function (resp) {
            if (!resp) {
                return;
            }
            var timestamps = [];
            Object.values(fftData.fftTimestamps[selectedAxis] || {}).forEach(function (items) {
                items.forEach(function (item) {
                    if (readingsList.includes(+item.readingFFT_id)) {
                        timestamps.push(item.timestamp);
                    }
                });
            });
            var inititalTimestampsUpdate = resp.timestamps.filter(function (item) {
                return timestamps.includes(item.timestamp);
            });
            var readingsListUpdate = inititalTimestampsUpdate.map(function (item) { return item.id; });
            if (readingsListUpdate.length === 0 || selectedType === 'latest') {
                inititalTimestampsUpdate = resp.timestamps.slice(-10);
                readingsListUpdate = inititalTimestampsUpdate.map(function (item) { return item.id; });
            }
            setHoldedReadingsList(readingsListUpdate);
            setSelectedInstallationPoint(installationPoint);
            setChartData(__assign(__assign({}, chartData), { series: [], inititalTimestamps: _uniq(inititalTimestampsUpdate.map(function (item) { return item.timestamp; })) }));
        })
            .finally(function () {
            setFftReadingsTimestampsLoader(false);
        });
    };
    var onDisplay = function () {
        setReadingsList(holdedReadingsList);
    };
    var closeBtn = (_jsxs(ButtonGroup, { style: { marginTop: '-7px' }, children: [_jsx(Button, { onClick: function () { return setIsExpanded(!isExpanded); }, color: "success", size: "sm", children: _jsx(BiExpandAlt, {}) }), _jsx(Button, { onClick: onCollapse, color: "warning", size: "sm", children: _jsx(FaRegWindowMinimize, {}) }), _jsx(Button, { onClick: onCancel, color: "danger", size: "sm", children: _jsx(FaTimes, {}) })] }));
    return (_jsxs(Modal, { className: "modal-response modal-response-plot", size: isExpanded ? 'xxxl' : 'xxl', toggle: onCancel, isOpen: true, children: [_jsx(ModalHeader, { toggle: onCancel, cssModule: { 'modal-title': 'w-100' }, close: closeBtn, children: _jsxs("div", { className: "d-flex justify-content-between align-items-center", children: [_jsxs("div", { children: [_jsx("span", { children: "Waterfall Plot" }), _jsx(GoDotFill, { className: "mx-3 mb-1" }), _jsx("span", { children: "FFT" })] }), _jsxs("div", { className: "d-flex justify-content-between", children: [_jsx(Input, { type: "select", id: "waterfall-installation-point", name: "waterfall-installation-point", className: "ms-2 me-2 w-280", onChange: function (ev) {
                                        var _a;
                                        return onChangeInstallationPoint((_a = equipment === null || equipment === void 0 ? void 0 : equipment.installationPoints) === null || _a === void 0 ? void 0 : _a.find(function (installationPoint) { return installationPoint.id === +ev.target.value; }));
                                    }, value: selectedInstallationPoint.id, children: (_b = equipment === null || equipment === void 0 ? void 0 : equipment.installationPoints) === null || _b === void 0 ? void 0 : _b.map(function (installationPoint) { return (_jsx("option", { value: installationPoint.id, children: installationPoint.name }, "waterfall-installation-point-".concat(installationPoint.id))); }) }), _jsxs(InputGroup, { className: "ms-2 me-2", children: [_jsxs(Input, { type: "select", id: "waterfall-installation-point", name: "waterfall-installation-point", className: "w-280", onChange: function (ev) {
                                                return setSelectedChartGroup(+ev.target.value);
                                            }, value: selectedChartGroup, children: [_jsx("option", { value: CHART_GROUPS.FFT_ACC, children: "Acceleration" }), _jsx("option", { value: CHART_GROUPS.FFT_VEL, children: "Velocity" })] }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: getUoms(chartIdentifier, measure, personalSettingMeasure) }) })] }), _jsxs(Input, { type: "select", id: "waterfall-installation-point", name: "waterfall-installation-point", className: "ms-2 me-2 w-280", onChange: function (ev) { return setSelectedAxis(+ev.target.value); }, value: selectedAxis, children: [_jsxs("option", { value: AXIS_X, children: [AXIS_NAMES[AXIS_X], " axis"] }), _jsxs("option", { value: AXIS_Y, children: [AXIS_NAMES[AXIS_Y], " axis"] }), _jsxs("option", { value: AXIS_Z, children: [AXIS_NAMES[AXIS_Z], " axis"] })] })] }), _jsx("div", {})] }) }), _jsx(ModalBody, { children: isPending || fftReadingsTimestampsLoader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "position-absolute", style: { right: 0, zIndex: 10, top: '65px' }, children: [_jsx(BatchTimestampsCalendar, { inititalTimestamps: chartData.inititalTimestamps, chartIdentifier: chartIdentifier, onApply: onApply, variant: selectedType === 'range' ? 'multi' : 'single', readingsLimit: readingsLimit }), _jsx(FormGroup, { children: _jsxs(Label, { className: "d-flex gap-2 align-items-center mt-2", children: ["Limit of FFTs", _jsx(Input, { className: "w-auto", value: readingsLimit, onChange: function (ev) {
                                                    Number(ev.target.value) > getMaximumTimestamps(installationPoint)
                                                        ? setReadingsLimit(Number(ev.target.value.slice(-1)))
                                                        : setReadingsLimit(Number(ev.target.value));
                                                }, type: "number", min: 1, max: getMaximumTimestamps(installationPoint) })] }) }), _jsxs("div", { className: "ms-3 mt-2 fw-bold fz-18", children: [_jsx(Label, { className: "mb-4 mt-2", children: "Choose Intervals:" }), _jsx(FormGroup, { children: _jsxs(Label, { check: true, children: [_jsx(Input, { name: "waterfall-interval-type", defaultChecked: selectedType === 'latest', onClick: function () { return setSelectedType('latest'); }, type: "radio" }), _jsx("span", { className: "ms-2", children: "Latests FFTs" })] }) }), _jsx(FormGroup, { children: _jsxs(Label, { check: true, children: [_jsx(Input, { name: "waterfall-interval-type", defaultChecked: selectedType === 'per_days', onClick: function () { return setSelectedType('per_days'); }, type: "radio" }), _jsxs("span", { className: "ms-2", children: ["Range between FFT", _jsx(Input, { disabled: selectedType !== 'per_days', onChange: function (ev) {
                                                                    return setDaysForPerDaysType(+ev.target.value);
                                                                }, className: "d-inline-block w-20 ms-1 me-1", type: "number", value: daysForPerDaysType }), _jsx("span", { className: "fz-12", children: "days" })] })] }) }), _jsx(FormGroup, { children: _jsxs(Label, { check: true, children: [_jsx(Input, { name: "waterfall-interval-type", defaultChecked: selectedType === 'selected', onClick: function () { return setSelectedType('selected'); }, type: "radio" }), _jsx("span", { className: "ms-2", children: "Selected FFTs" })] }) }), _jsx(FormGroup, { children: _jsxs(Label, { check: true, children: [_jsx(Input, { name: "waterfall-interval-type", defaultChecked: selectedType === 'range', onClick: function () { return setSelectedType('range'); }, type: "radio" }), _jsx("span", { className: "ms-2", children: "Selected FFTs Range" })] }) })] })] }), _jsxs(Row, { children: [_jsxs(Col, { md: isTrendVisible ? 8 : 12, children: [_jsx("div", { className: "position-relative mt-3 pb-3", style: { minHeight: 500 }, children: !isPending && chartData.series.length > 0 ? (_jsx(_Fragment, { children: _jsx(Plot, { ref: chartRef, data: trendFrequency && isTrendVisible
                                                        ? __spreadArray(__spreadArray([], chartData.series, true), [
                                                            getTrendSlice(FrequencyConverter.fromHz(getCalculatedTrendFrequency(trendFrequency, chartData.xMax, frequencyRange.to), currentSpeed).toType(units).value, chartData.yMax, readingsList),
                                                        ], false) : chartData.series, layout: {
                                                        width: isTrendVisible
                                                            ? (window.innerWidth - window.innerWidth * (isExpanded ? 0.15 : 0.25)) * 0.65
                                                            : window.innerWidth - window.innerWidth * (isExpanded ? 0.15 : 0.25),
                                                        height: window.innerHeight - 350,
                                                        margin: {
                                                            l: 0,
                                                            r: 0,
                                                            t: 0,
                                                            b: 0,
                                                        },
                                                        paper_bgcolor: bgColor,
                                                        plot_bgcolor: bgColor,
                                                        scene: {
                                                            xaxis: {
                                                                title: '',
                                                                nticks: 10,
                                                                tickmode: 'array',
                                                                color: axisColor,
                                                                tickvals: chartData.tickvals,
                                                                ticktext: chartData.ticktext,
                                                            },
                                                            yaxis: {
                                                                color: axisColor,
                                                                title: '',
                                                            },
                                                            zaxis: {
                                                                color: axisColor,
                                                                title: '',
                                                            },
                                                            bgcolor: bgColor,
                                                            camera: {
                                                                center: {
                                                                    x: 0,
                                                                    y: 0,
                                                                    z: -0.25,
                                                                },
                                                                eye: {
                                                                    x: isTrendVisible ? 1.75 : 1.15,
                                                                    y: isTrendVisible ? 1.75 : 1.15,
                                                                    z: isTrendVisible ? 1.75 : 1.15,
                                                                },
                                                            },
                                                            aspectratio: {
                                                                x: 1.25,
                                                                y: 1.25,
                                                                z: 0.5,
                                                            },
                                                        },
                                                        legend: {
                                                            orientation: 'v',
                                                            x: 0,
                                                            y: 0,
                                                            yanchor: 'bottom',
                                                            bgcolor: bgColor,
                                                        },
                                                        hoverlabel: {
                                                            bgcolor: bgColor,
                                                        },
                                                        autosize: true,
                                                    }, frames: [], config: {
                                                        modeBarButtons: [
                                                            [
                                                                'toImage',
                                                                'zoom3d',
                                                                'pan3d',
                                                                'tableRotation',
                                                                'resetCameraDefault3d',
                                                                'hoverClosest3d',
                                                            ],
                                                        ],
                                                        responsive: true,
                                                        displaylogo: false,
                                                        showTips: false,
                                                        displayModeBar: true,
                                                    } }) })) : (_jsxs("p", { className: "text-center h4", children: ["Select ", '"Interval Type"', " and press ", '"Display"', " to see the data"] })) }), !isPending && chartData.series.length > 0 ? (_jsx(_Fragment, { children: _jsxs("div", { className: "d-flex justify-content-left align-items-baseline", children: [_jsx("div", { className: "position-relative waterfall-footer", children: _jsx(FftXAxisDropdown, { isVisibleFullscreenChart: false, dispatch: function (action) { var _a; return setUnits((_a = action === null || action === void 0 ? void 0 : action.state) === null || _a === void 0 ? void 0 : _a.units); }, chartIdentifier: chartIdentifier, units: units, currentSpeed: currentSpeed }) }), _jsx(FrequencyRange, { setFrequencyRange: setFrequencyRange, currentSpeed: currentSpeed, isTrendVisible: isTrendVisible, setIsTrendVisible: setIsTrendVisible, units: units })] }) })) : (_jsx(_Fragment, {}))] }), isTrendVisible && !isPending && chartData.series.length > 0 ? (_jsx(Col, { className: "pr-5 position-relative", md: 4, children: _jsx(Trend, { setTrendFrequency: setTrendFrequency, trendFrequency: trendFrequency, series: chartData.series, currentSpeed: currentSpeed, chartIdentifier: chartIdentifier, units: units, from: +frequencyRange.from || 0, to: +Math.min(chartData.xMax, frequencyRange.to || Infinity) }) })) : (_jsx(_Fragment, {}))] })] })) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { className: "response-ignore", children: _jsx(Button, { size: "sm", outline: true, color: "success", onClick: onDisplay, children: "Display" }) }) })] }));
};
export default WaterfallPlotModal;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useClickAway } from '@uidotdev/usehooks';
import React, { memo } from 'react';
import { FaTimes } from 'react-icons/fa';
import '../../../../../../../shared/styles/SelectsGroup/components/index.scss';
import { selectSelectedCustomers } from '../../../../../../store/SelectedCustomersStore';
import { CustomersSelectTree } from '../CustomersSelectTree';
export var CustomersSelectModal = memo(function (_a) {
    var customersData = _a.customersData, onChangeHandler = _a.onChangeHandler, onClose = _a.onClose;
    var ref = useClickAway(onClose);
    var selectedCustomers = selectSelectedCustomers();
    return (_jsxs("dialog", { ref: ref, open: true, children: [_jsxs("header", { children: [_jsx("h3", { children: "Customers" }), _jsx(FaTimes, { onClick: onClose })] }), _jsx("div", { className: "content", children: _jsx(CustomersSelectTree, { customersData: customersData, onChangeHandler: onChangeHandler, selectedCustomers: selectedCustomers }) })] }));
});
CustomersSelectModal.displayName = 'CustomersSelectModal';

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import Loader from '../../../../../shared/components/Loader';
import { FacilitySelectModal } from './components/FacilitySelectModal';
export var FacilitySelect = memo(function (_a) {
    var isLoading = _a.isLoading, facilityList = _a.facilityList, selectedFacility = _a.selectedFacility, onChangeFacility = _a.onChangeFacility;
    var _b = useState(false), isVisibleModal = _b[0], setIsVisibleModal = _b[1];
    return (_jsx("div", { className: "header-select", children: isLoading ? (_jsx("span", { children: _jsx(Loader, { variant: "loader-xs" }) })) : (_jsxs(_Fragment, { children: [_jsxs("span", { onClick: function () { return setIsVisibleModal(true); }, children: [selectedFacility !== null && selectedFacility !== void 0 ? selectedFacility : 'Facility - ' + facilityList.length, _jsx(FaAngleDown, {})] }), isVisibleModal ? (_jsx(FacilitySelectModal, { onClose: function () { return setIsVisibleModal(false); }, facilityList: facilityList, onChangeFacility: onChangeFacility, selectedFacility: selectedFacility })) : (_jsx(_Fragment, {}))] })) }));
});
FacilitySelect.displayName = 'FacilitySelect';

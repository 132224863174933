import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useClickAway, useDebounce } from '@uidotdev/usehooks';
import React, { memo, useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { Input, Label } from 'reactstrap';
import '../../../../../../../shared/styles/SelectsGroup/components/index.scss';
export var ZoneSelectModal = memo(function (_a) {
    var zoneList = _a.zoneList, onChangeZone = _a.onChangeZone, onClose = _a.onClose, selectedZone = _a.selectedZone;
    var ref = useClickAway(onClose);
    var _b = useState(zoneList), list = _b[0], setList = _b[1];
    var _c = useState(''), searchTerm = _c[0], setSearchTerm = _c[1];
    var debouncedSearchTerm = useDebounce(searchTerm, 100);
    var handleChange = function (e) {
        setSearchTerm(e.target.value);
    };
    useEffect(function () {
        setList(zoneList.filter(function (name) { return name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()); }));
    }, [debouncedSearchTerm]);
    return (_jsxs("dialog", { ref: ref, open: true, children: [_jsxs("header", { children: [_jsx("h3", { children: "Zones" }), _jsx(FaTimes, { onClick: onClose })] }), _jsxs("div", { className: "content", children: [_jsx("search", { children: _jsxs(Label, { for: "zone-select-search", children: [_jsx(FaSearch, {}), _jsx(Input, { autoFocus: true, autoComplete: "off", type: "search", id: "zone-select-search", placeholder: "Search in filter", onChange: handleChange })] }) }), _jsx("section", { children: list.map(function (name) { return (_jsx("span", { onClick: function () {
                                onChangeZone(name);
                                onClose();
                            }, className: "radio-type ".concat(selectedZone === name ? 'active' : ''), children: name }, name)); }) })] }), _jsx("footer", { children: _jsx("span", { onClick: function () {
                        onChangeZone(null);
                        onClose();
                    }, children: "Reset" }) })] }));
});
ZoneSelectModal.displayName = 'ZoneSelectModal';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import '../../../shared/modals/machineInfo/MachineInfo.scss';
var ImpactVueForm = function (_a) {
    var impactVueFormData = _a.impactVueFormData, onSuccess = _a.onSuccess;
    var visible = impactVueFormData.visible, onCancel = impactVueFormData.onCancel, equipment = impactVueFormData.equipment, selectedCustomers = impactVueFormData.selectedCustomers, selectedLocations = impactVueFormData.selectedLocations;
    var _b = useState({
        equipment_id: equipment === null || equipment === void 0 ? void 0 : equipment.id,
        selected_customer_ids: selectedCustomers,
        selected_location_ids: (equipment === null || equipment === void 0 ? void 0 : equipment.id) ? null : selectedLocations,
        is_hfdvue: null,
    }), data = _b[0], setData = _b[1];
    var onConfirm = function () {
        if (data.is_hfdvue === null) {
            return toast.error('Select one of options', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        }
        Endpoints.remastered.saveImpactVueBatch(data).then(function (resp) {
            if (resp.success) {
                onCancel();
                onSuccess();
                return toast.success(resp.message || 'Successfully saved', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
            }
            return toast.error(resp.message || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        });
    };
    return (_jsxs(Modal, { className: "modal-response", size: "lg", toggle: onCancel, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["ImpactVue state for ", (equipment === null || equipment === void 0 ? void 0 : equipment.id) ? " equipment - ".concat(equipment.name) : ' all equipment'] }), _jsx(ModalBody, { className: "fz-13", children: _jsx(Row, { children: _jsxs(Col, { md: 12, className: "border-bottom pb-2 fw-bold fz-15", children: [_jsx(FormGroup, { className: "ms-4", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "is_hfdvue", checked: data.is_hfdvue === 1, onChange: function () { return setData(__assign(__assign({}, data), { is_hfdvue: 1 })); } }), "Enable ImpactVue"] }) }), _jsx(FormGroup, { className: "ms-4", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "is_hfdvue", checked: data.is_hfdvue === 0, onChange: function () { return setData(__assign(__assign({}, data), { is_hfdvue: 0 })); } }), "Disable ImpactVue"] }) })] }) }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onConfirm, children: "Save" })] }) })] }));
};
export default ImpactVueForm;

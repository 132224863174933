var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useContext, useEffect, useReducer, useState, } from 'react';
import { Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import DataTable from '../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../shared/config/mlSuggestedAlarmAdjustment/dataTableConfig';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { onChangeFilter, onDelete } from '../../../shared/features/mlSuggestedAlarmAdjustment';
import { onViewInstallationPoint } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../shared/helper/table';
import ConfirmModal from '../../../shared/modals/mlSuggestedAlarmAdjustment/ConfirmModal';
import { useConfirmModalActions } from '../../../shared/store/global/useConfirmModalStore';
import { selectMeasure, selectPersonalSettingMeasure, } from '../../../shared/store/global/useGlobalStore';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../store/SelectedLocationsStore';
var initialState = function (onViewInstallationPoint, measure, personalSettingMeasure) {
    var config = baseConfig(onViewInstallationPoint, measure, personalSettingMeasure);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'customer_name',
                    title: 'Customer',
                    sortable: true,
                },
                {
                    name: 'facility_name',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'location_name',
                    title: 'Location',
                    sortable: true,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                }
            ], config.columns, true) }),
    };
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations) {
    var config = state.config;
    setLoader(state, dispatch, true);
    Endpoints.remastered
        .getMlSuggestedAlarmAdjustmentList({
        query: __assign({ selected_customer_ids: selectedCustomers, selected_location_ids: selectedLocations, pagination: config.pagination, sort: config.sort, search: config.search }, config.filtersData),
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }) }),
                },
            });
        }
    });
};
export var MlSuggestedAlarmAdjustment = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Adjust Alarm Levels');
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useReducer(reducer, initialState(function (installationPointId, params) {
        return onViewInstallationPoint(installationPointId, selectedSystemType, params);
    }, measure, personalSettingMeasure)), state = _c[0], dispatch = _c[1];
    var config = state.config;
    var _d = useState({
        visible: false,
        id: 0,
        onCancel: function () {
            setConfirmModalState(__assign(__assign({}, confirmModalState), { visible: false, id: 0 }));
        },
    }), confirmModalState = _d[0], setConfirmModalState = _d[1];
    useEffect(function () {
        fetch(state, dispatch, selectedCustomers, selectedLocations);
    }, [refreshFlag, selectedCustomers, selectedLocations, config.filtersData]);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var callback = function () { return fetch(state, dispatch, selectedCustomers, selectedLocations); };
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { className: "table_wrap", children: [_jsx("h3", { className: "page-title", children: "Adjust Alarm Levels" }), _jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, callback); }, onPageSizeChange: function (pageSize) { return onPageSizeChange(pageSize, state, dispatch, callback); }, onSort: function (column_name, ev) { return onSort(column_name, state, dispatch, callback, ev); }, onSearch: function (search) { return onSearch(search, state, dispatch, callback); }, callbacks: {
                                    onChangeFilter: function (name, value) { return onChangeFilter(state, dispatch, name, value); },
                                    onDelete: function (id) {
                                        return onDelete(Endpoints.remastered.deleteMlSuggestedAlarmAdjustment, id, callback, setConfirmData);
                                    },
                                    onConfirm: function (id) { return setConfirmModalState(__assign(__assign({}, confirmModalState), { visible: true, id: id })); },
                                } })] }) }) }), _jsx(ConfirmModal, { onCancel: confirmModalState.onCancel, visible: confirmModalState.visible, callback: callback, endpoint: function (comment) {
                    return Endpoints.remastered.confirmMlSuggestedAlarmAdjustment(confirmModalState.id, { comment: comment });
                } })] }));
};
export default memo(MlSuggestedAlarmAdjustment);

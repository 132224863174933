var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import { memo, useMemo, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { READING_TYPES } from '../../constants';
import SensorsByEquipment from '../../shared/components/SensorsByEquipment';
import { ChartFeaturesStore } from '../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../shared/store/charts/chartsContent/InstallationPointStore';
import { isSupportZAxis } from '../../widgets/sensor';
import './ChangeAlarmLevels.scss';
import AxisType from './components/AxisType';
import { AlertLevelsTab, AlertLogTab, AnalystNotesTab, MlSuggestedLevelsTab, SuggestedLevelsTab, } from './components/tabs';
import { getAvailableChartIdentifiersByReadingType } from './helpers';
var ChangeAlarmLevelsModal = function (_a) {
    var _b;
    var onCancel = _a.onCancel;
    var _c = useState(false), smallLoader = _c[0], setSmallLoader = _c[1];
    var _d = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
        setStates: state.setStates,
    }); }, shallow), changeAlertConditions = _d.changeAlertConditions, setStates = _d.setStates;
    var readingTypeId = changeAlertConditions.readingTypeId;
    var _e = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
        mlSuggestedLevels: state.mlSuggestedLevels,
        installationPoint: state.installationPoint,
    }); }, shallow), mlSuggestedLevels = _e.mlSuggestedLevels, installationPoint = _e.installationPoint, readingTypes = _e.readingTypes;
    var isAdapter = _get(readingTypes, [readingTypeId, 'isCustom']) &&
        _get(readingTypes, [readingTypeId, 'groupId']) === 'custom';
    var equipment = _get(installationPoint, 'equipment', {});
    var _f = useState(installationPoint.id), selectedInstallationPointId = _f[0], setSelectedInstallationPointId = _f[1];
    var _g = useState(false), areAlertLevelsChanged = _g[0], setAreAlertLevelsChanged = _g[1];
    var resetSpots = function () {
        if (!areAlertLevelsChanged) {
            setStates({
                resetAlertConditions: __assign(__assign({}, changeAlertConditions), { levels: changeAlertConditions.storedLevels }),
            });
        }
        onCancel();
    };
    var _h = useState(readingTypeId ? [readingTypeId] : []), selectedReadingTypeIds = _h[0], setSelectedReadingTypeIds = _h[1];
    var tabContentMapping = useMemo(function () {
        var tabs = [
            {
                Component: AlertLevelsTab,
                title: 'Alert Levels',
            },
            {
                Component: SuggestedLevelsTab,
                title: 'Suggested Levels',
                hidden: isAdapter,
            },
            {
                Component: AnalystNotesTab,
                title: 'Alert Notes',
                hidden: isAdapter,
            },
            {
                Component: AlertLogTab,
                title: 'Alert Log',
            },
        ];
        if (_get(mlSuggestedLevels, [readingTypeId, 'id'])) {
            tabs.push({
                Component: MlSuggestedLevelsTab,
                title: 'ML-adjustment',
            });
        }
        return tabs;
    }, [mlSuggestedLevels, readingTypeId]);
    var _j = useState(tabContentMapping[0].title), activeTab = _j[0], setActiveTab = _j[1];
    var isActiveAlertLevels = activeTab === tabContentMapping[0].title;
    var chartIdentifiers = useMemo(function () {
        var _a;
        if (!readingTypeId)
            return [];
        if ((_a = readingTypes[readingTypeId]) === null || _a === void 0 ? void 0 : _a.isImpactVue) {
            return [readingTypeId];
        }
        return getAvailableChartIdentifiersByReadingType(READING_TYPES, readingTypeId);
    }, [readingTypeId]);
    var getModalTitle = function () {
        var chartTitle = _get(isAdapter ? readingTypes : READING_TYPES, [readingTypeId, 'title'], '');
        var axis = _get(isAdapter ? readingTypes : READING_TYPES, [readingTypeId, 'axis'], '');
        return "Settings of alarm levels for ".concat(chartTitle, " ").concat(axis);
    };
    return (_jsxs(Modal, { autoFocus: false, className: "modal-alarm-levels modal-response", size: "xxl", toggle: resetSpots, isOpen: true, children: [_jsxs(ModalHeader, { toggle: resetSpots, children: [getModalTitle(), smallLoader && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsxs(ModalBody, { className: "modal-alarm-levels__body", "data-is-loading": smallLoader, children: [_jsx(Nav, { tabs: true, children: tabContentMapping
                            .filter(function (_a) {
                            var hidden = _a.hidden;
                            return !hidden;
                        })
                            .map(function (_a) {
                            var title = _a.title;
                            return (_jsx(NavItem, { children: _jsx(NavLink, { active: activeTab === title, onClick: function () { return setActiveTab(title); }, children: title.toUpperCase() }) }, title));
                        }) }), _jsxs(Row, { className: "pt-3", children: [_jsx(Col, { md: 6, children: !isAdapter && (_jsx(SensorsByEquipment, { className: "select-installation-point", selectedInstallationPointId: selectedInstallationPointId, setSelectedInstallationPointId: setSelectedInstallationPointId, isDisabled: smallLoader, equipment: equipment })) }), _jsx(Col, { md: 6, className: "axis-type-container", children: chartIdentifiers.length > 1 && (_jsx(AxisType, { className: "axis-type", selectedReadingTypeIds: selectedReadingTypeIds, setSelectedReadingTypeIds: setSelectedReadingTypeIds, chartIdentifiers: chartIdentifiers, isSupportZAxis: isSupportZAxis((_b = installationPoint.sensor) === null || _b === void 0 ? void 0 : _b.version_type) })) })] }), _jsx(TabContent, { className: "mt-3", activeTab: activeTab, children: tabContentMapping
                            .filter(function (_a) {
                            var hidden = _a.hidden;
                            return !hidden;
                        })
                            .map(function (_a) {
                            var title = _a.title, Component = _a.Component;
                            return activeTab === title && (_jsx(TabPane, { tabId: title, children: _jsx(Component, { smallLoader: smallLoader, setSmallLoader: setSmallLoader, installationPointId: selectedInstallationPointId, selectedReadingTypeIds: selectedReadingTypeIds, onSelectReadingType: setSelectedReadingTypeIds, chartIdentifiers: chartIdentifiers, isAdapter: isAdapter }) }));
                        }) })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { disabled: smallLoader, size: "sm", outline: true, onClick: resetSpots, children: "Cancel" }), isActiveAlertLevels && (_jsxs(_Fragment, { children: [_jsx(Button, { disabled: smallLoader, size: "sm", color: "success", form: "alertLevelForm", "data-action": "save", onClick: function () { return setAreAlertLevelsChanged(true); }, type: "submit", children: "Save" }), _jsx(Button, { form: "alertLevelForm", "data-action": "save&close", size: "sm", color: "success", children: "Save & Close" })] }))] }) })] }));
};
export default memo(ChangeAlarmLevelsModal);

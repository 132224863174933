var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { memo, useContext, useEffect, useReducer } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import DataTable from '../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../shared/config/withoutAlarmLevels/dataTableConfig';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { exportToCsv, onChangeDateRangeFilter, onViewInstallationPoint, } from '../../../shared/features/shared';
import { onChangeFilter } from '../../../shared/features/withoutAlarmLevels';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../../shared/helper/table';
import { useResetDataTableEffect } from '../../../shared/hooks/useResetDataTableEffect';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../store/SelectedLocationsStore';
var initialState = {
    config: __assign(__assign({}, baseConfig), { columns: __spreadArray([
            {
                name: 'customer_name',
                title: 'Customer',
                sortable: true,
            },
            {
                name: 'facility_name',
                title: 'Facility',
                sortable: true,
            },
            {
                name: 'location_name',
                title: 'Location',
                sortable: true,
            },
            {
                name: 'equipment_name',
                title: 'Equipment',
                sortable: true,
            }
        ], baseConfig.columns, true) }),
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations) {
    var _a, _b, _c, _d;
    var config = state.config;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { loader: true }),
        },
    });
    Endpoints.remastered
        .getWithoutAlarmLevelsList({
        query: __assign(__assign({ selected_customer_ids: selectedCustomers, selected_location_ids: selectedLocations, pagination: config.pagination, sort: config.sort, search: config.search }, config.filtersData), { date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                ? moment((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                : '', date_to: ((_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.dateRange[1])
                ? moment((_d = config === null || config === void 0 ? void 0 : config.filtersData) === null || _d === void 0 ? void 0 : _d.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                : '' }),
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }) }),
                },
            });
        }
    });
};
var WithoutAlarmLevelsPage = function (_a) {
    var _b, _c;
    var _d = _a.refreshFlag, refreshFlag = _d === void 0 ? null : _d;
    useTitle('Without Alarm Levels');
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _e = useReducer(reducer, initialState), state = _e[0], dispatch = _e[1];
    var config = state.config;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    useResetDataTableEffect([selectedCustomers, selectedLocations, (_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange], state, dispatch);
    useEffect(function () {
        fetch(state, dispatch, selectedCustomers, selectedLocations);
    }, [refreshFlag, selectedCustomers, selectedLocations, config.filtersData, (_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.dateRange]);
    return (_jsx(React.Fragment, { children: _jsx(Row, { children: _jsxs(Col, { children: [_jsxs("h3", { className: "page-title", children: ["Sensors without alarm levels", config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                    var _a, _b, _c, _d;
                                    return exportToCsv(Endpoints.remastered.getWithoutAlarmLevelsListCsv, 'without-alarm-levels', {
                                        query: __assign(__assign({ selected_customer_ids: selectedCustomers, selected_location_ids: selectedLocations, sort: config.sort, search: config.search }, config.filtersData), { date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                                                ? moment((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                : '', date_to: ((_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.dateRange[1])
                                                ? moment((_d = config === null || config === void 0 ? void 0 : config.filtersData) === null || _d === void 0 ? void 0 : _d.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                : '' }),
                                        isBlob: true,
                                    });
                                }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }), _jsx(DataTable, { config: config, onPageChange: function (page) {
                            return onPageChange(page, state, dispatch, function () {
                                return fetch(state, dispatch, selectedCustomers, selectedLocations);
                            });
                        }, onPageSizeChange: function (pageSize) {
                            return onPageSizeChange(pageSize, state, dispatch, function () {
                                return fetch(state, dispatch, selectedCustomers, selectedLocations);
                            });
                        }, onSort: function (column_name, ev) {
                            return onSort(column_name, state, dispatch, function () { return fetch(state, dispatch, selectedCustomers, selectedLocations); }, ev);
                        }, onSearch: function (search) {
                            return onSearch(search, state, dispatch, function () {
                                return fetch(state, dispatch, selectedCustomers, selectedLocations);
                            });
                        }, callbacks: {
                            onChangeFilter: function (name, value) { return onChangeFilter(state, dispatch, name, value); },
                            onViewClick: function (id) { return onViewInstallationPoint(id, selectedSystemType); },
                            onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                        } })] }) }) }));
};
export default memo(WithoutAlarmLevelsPage);

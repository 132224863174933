import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import React from 'react';
import Endpoints from '../../../../api/endpoints/endpoints';
import SubmittedReportAi from '../../../../shared/components/dashboard/cards/SubmittedReportAi';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { get } from '../../../../storage/tableColumn';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../store/SelectedLocationsStore';
var SubmittedReportAiCardWrapper = function (_a) {
    var refreshFlag = _a.refreshFlag, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var tableName = 'SubmittedReportAiRemastered';
    var visibleState = get(tableName);
    var tableColumns = [
        {
            name: 'customer_name',
            visible: _has(visibleState, 'customer_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.customer_name : true,
            title: 'Customer',
            sortable: false,
        },
    ];
    return (_jsxs(_Fragment, { children: [isSelected ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'SubmittedReportAi', setActualLayout); } })] })) : (_jsx(_Fragment, {})), _jsx(SubmittedReportAi, { deps: [selectedCustomers, selectedLocations, refreshFlag], title: 'Action Items - Completion Report Submitted', tableName: tableName, tableColumns: tableColumns, endpoint: function (hideHardwareAi, sort) {
                    return Endpoints.remastered.getDashboardSubmittedReportActionList({
                        query: {
                            selected_customer_ids: selectedCustomers,
                            selected_location_ids: selectedLocations,
                            hide_hardware_ai: +hideHardwareAi,
                            sort: sort,
                            onlyActive: 1,
                        },
                    });
                } })] }));
};
export default SubmittedReportAiCardWrapper;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useReducer, useState, } from 'react';
import { Col, Row } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import { useTitle } from '../../../../customHooks';
import DataTable from '../../../../shared/components/shared/Table/Table';
import Tabs from '../../../../shared/components/twfDetections/reports/Tabs/Tabs';
import { config as baseConfig } from '../../../../shared/config/twfDetections/reports/dataTableConfig';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { onViewImages, onViewInstallationPoint } from '../../../../shared/features/shared';
import { reducer } from '../../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../../shared/helper/table';
import ImagesModal from '../../../../shared/modals/imagesModal/ImagesModal';
import '../../../../shared/styles/twfDetections/twfDetections.scss';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../store/SelectedLocationsStore';
var initialState = function (onViewInstallationPoint) {
    var config = baseConfig(onViewInstallationPoint);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'customer_name',
                    title: 'Customer',
                    sortable: true,
                },
                {
                    name: 'facility_name',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'location_name',
                    title: 'Location',
                    sortable: true,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                }
            ], config.columns, true) }),
    };
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations, userId) {
    var config = state.config;
    setLoader(state, dispatch, true);
    Endpoints.remastered
        .getTwfDataActionListReports({
        query: {
            selected_customer_ids: selectedCustomers,
            selected_location_ids: selectedLocations,
            pagination: config.pagination,
            sort: config.sort,
            search: config.search,
            user_id: userId,
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, filtersData: __assign(__assign({}, config.filtersData), { users: resp.users }), pagination: __assign(__assign({}, config.pagination), { total: resp.total }) }),
                },
            });
        }
    });
};
var TwfDetectionsReportsPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('TWF Detections Reports');
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useReducer(reducer, initialState(function (installationPointId, params) {
        return onViewInstallationPoint(installationPointId, selectedSystemType, params);
    })), state = _c[0], dispatch = _c[1];
    var config = state.config;
    var _d = useState({
        visible: false,
        images: [],
    }), imagesModalData = _d[0], setImagesModalData = _d[1];
    var _e = useState(null), selectedUser = _e[0], setSelectedUser = _e[1];
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    useEffect(function () {
        fetch(state, dispatch, selectedCustomers, selectedLocations, selectedUser);
    }, [refreshFlag, selectedCustomers, selectedLocations, selectedUser]);
    var callback = function () { return fetch(state, dispatch, selectedCustomers, selectedLocations, selectedUser); };
    return (_jsxs(_Fragment, { children: [_jsx(Row, { className: "page-TwfDetectionsReports", children: _jsxs(Col, { children: [_jsx(Tabs, { activeTab: "reports" }), _jsx("div", { className: "table_wrap", children: _jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, callback); }, onPageSizeChange: function (pageSize) { return onPageSizeChange(pageSize, state, dispatch, callback); }, onSort: function (column_name, ev) { return onSort(column_name, state, dispatch, callback, ev); }, onSearch: function (search) { return onSearch(search, state, dispatch, callback); }, callbacks: {
                                    onViewImages: function (images) { return onViewImages(setImagesModalData, images); },
                                    setSelectedUser: setSelectedUser,
                                } }) })] }) }), _jsx(ImagesModal, { images: imagesModalData.images.filter(function (image) { return image; }), onCancel: function () { return setImagesModalData({ visible: false, images: [] }); }, visible: imagesModalData.visible })] }));
};
export default TwfDetectionsReportsPage;

import { useMutation } from '@tanstack/react-query';
import Api from '../../../api/api';
var heatsinkBatchUpdateFn = function (isHeatsink, selectedCustomers, selectedLocations, equipmentId) {
    return Api.post('all-analytics/installation-point-settings/batch-heat-sink-update', {
        is_heatsink: +isHeatsink,
        selected_customer_ids: selectedCustomers,
        selected_location_ids: selectedLocations,
        equipment_id: equipmentId,
    });
};
export var useHeatsinkBatchUpdateMutation = function () {
    return useMutation({
        mutationFn: function (_a) {
            var isHeatsink = _a.isHeatsink, selectedCustomers = _a.selectedCustomers, selectedLocations = _a.selectedLocations, equipmentId = _a.equipmentId;
            return heatsinkBatchUpdateFn(isHeatsink, selectedCustomers, selectedLocations, equipmentId);
        },
        onSuccess: function () { },
    });
};

import { each as _each, get as _get, size as _size } from 'lodash';
import { isCfChart } from '../../../../../helper/chart';
import { calculateCurrentLevel } from './alertConditions';
export var getZones = function (chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure) {
    if (isCfChart(chartIdentifier)) {
        return [];
    }
    if (_size(alertConditions) === 0 || !_get(alertConditions, 'conditions')) {
        return [];
    }
    var zones = [];
    var currentLevels = calculateCurrentLevel(chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure);
    currentLevels.forEach(function (currentLevel) {
        var caution = currentLevel.caution, cautionCondition = currentLevel.cautionCondition, warning = currentLevel.warning, warningCondition = currentLevel.warningCondition;
        caution = Number(caution);
        warning = Number(warning);
        cautionCondition = cautionCondition || '>=';
        warningCondition = warningCondition || '>=';
        var zonesCondition = {
            getCautionAndWarningMoreZones: (cautionCondition === '>' || cautionCondition === '>=') &&
                (warningCondition === '>' || warningCondition === '>='),
            getCautionLessWarningMoreZones: (cautionCondition === '<' || cautionCondition === '<=') &&
                (warningCondition === '>' || warningCondition === '>='),
            getCautionMoreWarningLessZones: (cautionCondition === '>' || cautionCondition === '>=') &&
                (warningCondition === '<' || warningCondition === '<='),
            getCautionAndWarningLessZones: (cautionCondition === '<' || cautionCondition === '<=') &&
                (warningCondition === '<' || warningCondition === '<='),
            getCautionAndWarningEqualZones: cautionCondition === '=' && warningCondition === '=',
            getCautionEqualWarningLessZones: cautionCondition === '=' && (warningCondition === '<=' || warningCondition === '<'),
            getCautionEqualWarningMoreZones: cautionCondition === '=' && (warningCondition === '>=' || warningCondition === '>'),
            getCautionLessWarningEqualZones: (cautionCondition === '<=' || cautionCondition === '<') && warningCondition === '=',
            getCautionMoreWarningEqualZones: (cautionCondition === '>=' || cautionCondition === '>') && warningCondition === '=',
        };
        var zonesData = {
            getCautionAndWarningMoreZones: getCautionAndWarningMoreZones({ caution: caution, warning: warning }),
            getCautionLessWarningMoreZones: getCautionLessWarningMoreZones({ caution: caution, warning: warning }),
            getCautionMoreWarningLessZones: getCautionMoreWarningLessZones({ caution: caution, warning: warning }),
            getCautionAndWarningLessZones: getCautionAndWarningLessZones({ caution: caution, warning: warning }),
            getCautionAndWarningEqualZones: getCautionAndWarningEqualZones({ caution: caution, warning: warning }),
            getCautionEqualWarningLessZones: getCautionEqualWarningLessZones({ caution: caution, warning: warning }),
            getCautionEqualWarningMoreZones: getCautionEqualWarningMoreZones({ caution: caution, warning: warning }),
            getCautionLessWarningEqualZones: getCautionLessWarningEqualZones({ caution: caution, warning: warning }),
            getCautionMoreWarningEqualZones: getCautionMoreWarningEqualZones({ caution: caution, warning: warning }),
        };
        _each(zonesCondition, function (condition, name) {
            if (condition) {
                zones = zonesData[name];
            }
        });
    });
    return zones;
};
var getCautionAndWarningMoreZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.warning,
                color: '#eab700',
            },
            {
                color: '#ff0000',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.caution,
            color: '#ff0000',
        },
        {
            color: '#eab700',
        },
    ];
};
var getCautionAndWarningLessZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning,
                color: '#ff0000',
            },
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionAndWarningEqualZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution - (2 / 100) * currentLevel.caution,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning - (2 / 100) * currentLevel.warning,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.warning,
                color: '#ff0000',
            },
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning - (2 / 100) * currentLevel.warning,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution - (2 / 100) * currentLevel.caution,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionEqualWarningLessZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution + (3 / 100) * currentLevel.caution,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.caution - (3 / 100) * currentLevel.caution,
            color: '#eab700',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionEqualWarningMoreZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution - (3 / 100) * currentLevel.caution,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning,
                color: '#7cb5ec',
            },
            {
                color: '#ff0000',
            },
        ];
    }
    return [
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionLessWarningEqualZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning - (1 / 100) * currentLevel.warning,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.warning,
                color: '#ff0000',
            },
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning - (2 / 100) * currentLevel.warning,
            color: '#eab700',
        },
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionMoreWarningEqualZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.warning - (2 / 100) * currentLevel.warning,
                color: '#eab700',
            },
            {
                value: currentLevel.warning,
                color: '#ff0000',
            },
            {
                color: '#eab700',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning - (2 / 100) * currentLevel.warning,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution,
            color: '#7cb5ec',
        },
        {
            color: '#eab700',
        },
    ];
};
var getCautionLessWarningMoreZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning,
                color: '#7cb5ec',
            },
            {
                color: '#ff0000',
            },
        ];
    }
    return [
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionMoreWarningLessZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution,
            color: '#7cb5ec',
        },
        {
            color: '#eab700',
        },
    ];
};

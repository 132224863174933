var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { intersection as _intersection } from 'lodash';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CustomersSelectTree } from '../../components/SelectsGroup/components/CustomersSelect/components/CustomersSelectTree';
import { LocationsSelectTree } from '../../components/SelectsGroup/components/LocationsSelect/components/LocationsSelectTree';
import { getAllChildrenIds, onChangeLocationsHandler, } from '../../components/SelectsGroup/components/LocationsSelect/features';
import { useCreateAnalystFilterMutation } from '../../services/analystFilters/useCreateAnalystFilterMutation';
import { useUpdateAnalystFilterMutation } from '../../services/analystFilters/useUpdateAnalystFilterMutation';
import { useCustomersQuery } from '../../services/useCustomersQuery';
import { useLocationsTreeQuery } from '../../services/useLocationsTreeQuery';
import { selectFilterNameManageAnalystFilter, selectIdManageAnalystFilter, selectSelectedCustomersManageAnalystFilter, selectSelectedLocationsManageAnalystFilter, useManageAnalystFilterActions, } from '../../store/ManageAnalystFilterStore';
import { useSelectedCustomersActions } from '../../store/SelectedCustomersStore';
import { useSelectedLeadAnalystActions } from '../../store/SelectedLeadAnalystStore';
import { useSelectedLocationsActions } from '../../store/SelectedLocationsStore';
export var ManageAnalystFilterModal = function () {
    var _a = useManageAnalystFilterActions(), setIsVisible = _a.setIsVisible, setSelectedCustomers = _a.setSelectedCustomers, setSelectedLocations = _a.setSelectedLocations, setFilterName = _a.setFilterName, setId = _a.setId;
    var id = selectIdManageAnalystFilter();
    var filterName = selectFilterNameManageAnalystFilter();
    var selectedCustomers = selectSelectedCustomersManageAnalystFilter();
    var selectedLocations = selectSelectedLocationsManageAnalystFilter();
    var setSelectedLeadAnalyst = useSelectedLeadAnalystActions().setSelectedLeadAnalyst;
    var setSelectedLocationsMainFilter = useSelectedLocationsActions().setSelectedLocations;
    var setSelectedCustomersMainFilter = useSelectedCustomersActions().setSelectedCustomers;
    var _b = useLocationsTreeQuery({
        selectedCustomers: selectedCustomers,
    }), locationsTreeData = _b.data, locationsTreeIsLoading = _b.isLoading, locationsTreeIsFetching = _b.isFetching;
    var createAnalystFilterMutate = useCreateAnalystFilterMutation().mutate;
    var updateAnalystFilterMutate = useUpdateAnalystFilterMutation().mutate;
    var list = locationsTreeData.list;
    var onSave = function () {
        var customers = selectedCustomers.map(function (customerId) {
            var filterList = list.filter(function (_a) {
                var customer_id = _a.customer_id;
                return customer_id === customerId;
            });
            var data = [];
            filterList.forEach(function (locationTree) {
                data = __spreadArray(__spreadArray([], data, true), getAllChildrenIds(locationTree), true);
            });
            return {
                customer_id: customerId,
                locations: _intersection(data, selectedLocations),
            };
        });
        if (!filterName || !customers.length) {
            toast.error(!filterName ? 'Enter filter name' : 'Choose at least one customer', {
                icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
            });
            return;
        }
        (id ? updateAnalystFilterMutate : createAnalystFilterMutate)({
            id: id,
            name: filterName,
            description: '',
            customers: customers,
        }, {
            onSuccess: function () {
                toast.success("Successfully ".concat(id ? 'updated' : 'created'), {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                onClose();
                setSelectedLeadAnalyst([]);
                setSelectedLocationsMainFilter([]);
                setSelectedCustomersMainFilter([]);
            },
        });
    };
    var customersData = useCustomersQuery().data;
    var onClose = function () {
        setFilterName('');
        setSelectedCustomers([]);
        setSelectedLocations([]);
        setId(null);
        setIsVisible(false);
    };
    var onChangeCustomersHandler = function (items) {
        var selectedCustomerIds = [];
        items.forEach(function (_a) {
            var id = _a.id;
            selectedCustomerIds.push(id);
        });
        setSelectedLocations([]);
        setSelectedCustomers(selectedCustomerIds);
    };
    return (_jsxs(Modal, { className: "custom-tree", toggle: onClose, isOpen: true, centered: true, size: "xl", children: [_jsx(ModalHeader, { toggle: onClose, children: "Add new filter" }), _jsxs(ModalBody, { children: [_jsx(Label, { for: "name", children: "Name:" }), _jsx(Input, { id: "name", bsSize: 'lg', label: "name", type: "input", required: true, placeholder: "Enter filter name...", value: filterName, autoComplete: "off", onChange: function (event) { return setFilterName(event.target.value); } }), _jsxs("div", { className: "mt-4 content d-flex", children: [_jsx("div", { className: "w-55", children: _jsx(CustomersSelectTree, { customersData: customersData, onChangeHandler: onChangeCustomersHandler, selectedCustomers: selectedCustomers }) }), _jsx("div", { className: "w-100", children: _jsx(LocationsSelectTree, { locationsTreeData: locationsTreeData, locationsTreeIsLoading: locationsTreeIsLoading, locationsTreeIsFetching: locationsTreeIsFetching, onChangeHandler: function (item) {
                                        return onChangeLocationsHandler(item, selectedLocations, setSelectedLocations);
                                    }, selectedLocations: selectedLocations }) })] })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onClose, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onSave, children: "Save" })] }) })] }));
};
ManageAnalystFilterModal.displayName = 'ManageAnalystFilterModal';

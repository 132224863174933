import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useClickAway, useDebounce } from '@uidotdev/usehooks';
import React, { memo, useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { Input, Label } from 'reactstrap';
import '../../../../../../../shared/styles/SelectsGroup/components/index.scss';
export var FacilitySelectModal = memo(function (_a) {
    var facilityList = _a.facilityList, onChangeFacility = _a.onChangeFacility, onClose = _a.onClose, selectedFacility = _a.selectedFacility;
    var ref = useClickAway(onClose);
    var _b = useState(facilityList), list = _b[0], setList = _b[1];
    var _c = useState(''), searchTerm = _c[0], setSearchTerm = _c[1];
    var debouncedSearchTerm = useDebounce(searchTerm, 100);
    var handleChange = function (e) {
        setSearchTerm(e.target.value);
    };
    useEffect(function () {
        setList(facilityList.filter(function (_a) {
            var name = _a.name;
            return name.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
        }));
    }, [debouncedSearchTerm]);
    return (_jsxs("dialog", { ref: ref, open: true, children: [_jsxs("header", { children: [_jsx("h3", { children: "Facilities" }), _jsx(FaTimes, { onClick: onClose })] }), _jsxs("div", { className: "content", children: [_jsx("search", { children: _jsxs(Label, { for: "facility-select-search", children: [_jsx(FaSearch, {}), _jsx(Input, { autoFocus: true, autoComplete: "off", type: "search", id: "facility-select-search", placeholder: "Search in filter", onChange: handleChange })] }) }), _jsx("section", { children: list.map(function (_a) {
                            var name = _a.name;
                            return (_jsx("span", { onClick: function () {
                                    onChangeFacility(name);
                                    onClose();
                                }, className: "radio-type ".concat(selectedFacility === name ? 'active' : ''), children: name }, name));
                        }) })] }), _jsx("footer", { children: _jsx("span", { onClick: function () {
                        onChangeFacility(null);
                        onClose();
                    }, children: "Reset" }) })] }));
});
FacilitySelectModal.displayName = 'FacilitySelectModal';

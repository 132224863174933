var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import DataTable from '../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../shared/config/alarmLevelsVerification/dataTableConfig';
import { onChangeHighValuesFilter } from '../../../shared/features/alarmLevelsVerification';
import { onConfirm } from '../../../shared/features/dashboard/autoAi';
import { onChangeDateRangeFilter } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../shared/helper/table';
import { useResetDataTableEffect } from '../../../shared/hooks/useResetDataTableEffect';
import { useConfirmModalActions } from '../../../shared/store/global/useConfirmModalStore';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../store/SelectedLocationsStore';
var initialState = function () {
    var config = baseConfig();
    return {
        config: __assign({}, config),
    };
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations) {
    var _a, _b, _c;
    var config = state.config;
    setLoader(state, dispatch, true);
    var query = {
        selected_customer_ids: selectedCustomers,
        selected_location_ids: selectedLocations,
        pagination: config.pagination,
        sort: config.sort,
        search: config.search,
        date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
            ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
            : '',
        date_to: ((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[1])
            ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
            : '',
    };
    if ((_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.highValues) {
        var highValuesFilter_1 = config.filtersData.highValues;
        Object.keys(highValuesFilter_1).forEach(function (key) {
            var type = key;
            if (!highValuesFilter_1[type].isEnabled) {
                return;
            }
            // @ts-ignore
            query[type] = {
                fromValue: highValuesFilter_1[type].fromValue,
                toValue: highValuesFilter_1[type].toValue,
            };
        });
    }
    Endpoints.remastered
        .getAlarmLevelsVerificationList({
        query: query,
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }) }),
                },
            });
        }
    });
};
var endpointConfirm = function (data) {
    return Endpoints.remastered.confirmAutoAlertCondition(data);
};
var AlarmLevelsVerificationPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Alarm Levels Verification');
    var _c = useReducer(reducer, initialState()), state = _c[0], dispatch = _c[1];
    var config = state.config;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    useResetDataTableEffect([selectedCustomers, selectedLocations, config.filtersData, config.filtersData.dateRange], state, dispatch);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    useEffect(function () {
        fetch(state, dispatch, selectedCustomers, selectedLocations);
    }, [refreshFlag, selectedCustomers, selectedLocations, config.filtersData, config.filtersData.dateRange]);
    return (_jsx(React.Fragment, { children: _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { className: "table_wrap align-pagination-section move-pagination-top", children: [_jsx("h3", { className: "page-title", children: "Alarm Levels Verification" }), _jsx(DataTable, { config: config, onPageChange: function (page) {
                                return onPageChange(page, state, dispatch, function () {
                                    return fetch(state, dispatch, selectedCustomers, selectedLocations);
                                });
                            }, onPageSizeChange: function (pageSize) {
                                return onPageSizeChange(pageSize, state, dispatch, function () {
                                    return fetch(state, dispatch, selectedCustomers, selectedLocations);
                                });
                            }, onSort: function (column_name, ev) {
                                return onSort(column_name, state, dispatch, function () { return fetch(state, dispatch, selectedCustomers, selectedLocations); }, ev);
                            }, callbacks: {
                                onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                                onChangeHighValues: function (type, isEnabled, fromValue, toValue) { return onChangeHighValuesFilter(state, dispatch, type, isEnabled, fromValue, toValue); },
                                onConfirm: function (installationPointId, readingTypeId) {
                                    return onConfirm(setConfirmData, function (data) { return endpointConfirm(data); }, function () { return fetch(state, dispatch, selectedCustomers, selectedLocations); }, installationPointId, readingTypeId);
                                },
                            }, onSearch: function (search) {
                                return onSearch(search, state, dispatch, function () {
                                    return fetch(state, dispatch, selectedCustomers, selectedLocations);
                                });
                            } })] }) }) }) }));
};
export default AlarmLevelsVerificationPage;

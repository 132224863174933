// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selects-group {
  display: flex;
}
.selects-group .header-select {
  height: 38px;
  position: relative;
}
.selects-group .header-select > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-width: 170px;
  border: 2px solid rgba(0, 0, 0, 0.3137254902);
  padding: 3px 14px;
  cursor: pointer;
  border-radius: 8px;
}
.selects-group .header-select > span svg {
  margin-left: 10px;
}
.selects-group .header-select > span:has(.loader) {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/SelectsGroup/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAC;EACC,YAAA;EACA,kBAAA;AAEF;AADE;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,gBAAA;EACA,6CAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AAGH;AAFG;EACC,iBAAA;AAIJ;AAFG;EACC,uBAAA;AAIJ","sourcesContent":[".selects-group {\n    display: flex;\n\t& .header-select {\n\t\theight: 38px;\n\t\tposition: relative;\n\t\t& > span {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: space-between;\n\t\t\theight: 100%;\n\t\t\tmin-width: 170px;\n\t\t\tborder: 2px solid #00000050;\n\t\t\tpadding: 3px 14px;\n\t\t\tcursor: pointer;\n\t\t\tborder-radius: 8px;\n\t\t\t& svg {\n\t\t\t\tmargin-left: 10px;\n\t\t\t}\n\t\t\t&:has(.loader) {\n\t\t\t\tjustify-content: center;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

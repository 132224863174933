var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDebounce } from '@uidotdev/usehooks';
import { memo, useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Input, Label } from 'reactstrap';
export var CustomersSelectTree = memo(function (_a) {
    var customersData = _a.customersData, onChangeHandler = _a.onChangeHandler, selectedCustomers = _a.selectedCustomers;
    var customers = customersData.customers;
    var _b = useState(customers), list = _b[0], setList = _b[1];
    var _c = useState(''), searchTerm = _c[0], setSearchTerm = _c[1];
    var debouncedSearchTerm = useDebounce(searchTerm, 100);
    var handleChange = function (e) {
        setSearchTerm(e.target.value);
    };
    useEffect(function () {
        setList(customers.filter(function (_a) {
            var name = _a.name;
            return name.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
        }));
    }, [debouncedSearchTerm]);
    var handleCustomerSelection = function (name, customer_id) {
        var selectedSet = new Set(selectedCustomers);
        var selectedList = customers
            .filter(function (el) { return selectedSet.has(el.customer_id); })
            .map(function (el) { return ({ name: el.name, id: el.customer_id }); });
        return selectedSet.has(customer_id)
            ? selectedList.filter(function (el) { return el.id !== customer_id; })
            : __spreadArray(__spreadArray([], selectedList, true), [{ name: name, id: customer_id }], false);
    };
    return (_jsxs(_Fragment, { children: [_jsx("search", { children: _jsxs(Label, { for: "customers-select-search", children: [_jsx(FaSearch, {}), _jsx(Input, { autoFocus: true, autoComplete: "off", type: "search", id: "customers-select-search", placeholder: "Search in filter", onChange: handleChange, style: { backgroundColor: 'transparent' } })] }) }), _jsx("section", { children: list.map(function (_a) {
                    var name = _a.name, customer_id = _a.customer_id;
                    return (_jsx("span", { onClick: function () { return onChangeHandler(handleCustomerSelection(name, customer_id)); }, className: "checkbox-type ".concat(selectedCustomers.includes(customer_id) ? 'active' : ''), children: name }, customer_id));
                }) }), _jsxs("footer", { children: [_jsx("span", { onClick: function () { return onChangeHandler(customers.map(function (el) { return ({ name: el.name, id: el.customer_id }); })); }, children: "Select all" }), _jsx("span", { onClick: function () { return onChangeHandler([]); }, children: "Reset" })] })] }));
});
CustomersSelectTree.displayName = 'CustomersSelectTree';

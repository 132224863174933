import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { analystFiltersKey } from './useAnalystFiltersQuery';
var createAnalystFilterFn = function (name, description, customers) {
    return Api.post('all-analytics/analyst-filter', {
        name: name,
        description: description,
        customers: customers,
    });
};
export var useCreateAnalystFilterMutation = function () {
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var name = _a.name, description = _a.description, customers = _a.customers;
            return createAnalystFilterFn(name, description, customers);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: analystFiltersKey() });
        },
    });
};

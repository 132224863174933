var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import DataTable from '../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../shared/config/badData/dataTableConfig';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { onClickFilterTag, onDeleteBadDataItem, onShowChartImages, setSelectedReadingTypes, } from '../../../shared/features/badData';
import { exportToCsv, onChangeDateRangeFilter, onViewInstallationPoint, } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../shared/helper/table';
import { useResetDataTableEffect } from '../../../shared/hooks/useResetDataTableEffect';
import ImagesModal from '../../../shared/modals/imagesModal/ImagesModal';
import { useConfirmModalActions } from '../../../shared/store/global/useConfirmModalStore';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../store/SelectedLocationsStore';
var initialState = function (onViewInstallationPoint) {
    var config = baseConfig(onViewInstallationPoint);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'customer_name',
                    title: 'Customer',
                    sortable: true,
                },
                {
                    name: 'facility_name',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'location_name',
                    title: 'Location',
                    sortable: true,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                }
            ], config.columns, true) }),
    };
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations) {
    var _a, _b;
    var config = state.config;
    setLoader(state, dispatch, true);
    Endpoints.remastered
        .getBadDataList({
        query: {
            selected_customer_ids: selectedCustomers,
            selected_location_ids: selectedLocations,
            pagination: config.pagination,
            sort: config.sort,
            search: config.search,
            selected_tags: config.filtersData ? config.filtersData.selectedTags : '',
            selected_reading_types: config.filtersData ? config.filtersData.selectedReadingTypes : '',
            date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                : '',
            date_to: ((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[1])
                ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                : '',
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }) }),
                },
            });
        }
    });
};
var BadDataPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Bad Data Items');
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useReducer(reducer, initialState(function (installationPointId) { return onViewInstallationPoint(installationPointId, selectedSystemType); })), state = _c[0], dispatch = _c[1];
    var config = state.config;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var _d = useState({
        visible: false,
        images: [],
    }), imagesModalData = _d[0], setImagesModalData = _d[1];
    useResetDataTableEffect([selectedCustomers, selectedLocations, config.filtersData, config.filtersData.dateRange], state, dispatch);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    useEffect(function () {
        fetch(state, dispatch, selectedCustomers, selectedLocations);
    }, [refreshFlag, selectedCustomers, selectedLocations, config.filtersData, config.filtersData.dateRange]);
    return (_jsxs(React.Fragment, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { className: "table_wrap align-pagination-section move-pagination-top", children: [_jsxs("h3", { className: "page-title", children: ["Bad sensors", config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                            return exportToCsv(Endpoints.remastered.getBadDataListCsv, 'bad-data', {
                                                query: {
                                                    selected_customer_ids: selectedCustomers,
                                                    selected_location_ids: selectedLocations,
                                                    sort: config.sort,
                                                    search: config.search,
                                                    selected_tags: config.filtersData ? config.filtersData.selectedTags : '',
                                                    selected_reading_types: config.filtersData
                                                        ? config.filtersData.selectedReadingTypes
                                                        : '',
                                                    date_from: config.filtersData.dateRange[0]
                                                        ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                        : '',
                                                    date_to: config.filtersData.dateRange[1]
                                                        ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                        : '',
                                                },
                                                isBlob: true,
                                            });
                                        }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }), _jsx(DataTable, { config: config, onPageChange: function (page) {
                                    return onPageChange(page, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedCustomers, selectedLocations);
                                    });
                                }, onPageSizeChange: function (pageSize) {
                                    return onPageSizeChange(pageSize, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedCustomers, selectedLocations);
                                    });
                                }, onSort: function (column_name, ev) {
                                    return onSort(column_name, state, dispatch, function () { return fetch(state, dispatch, selectedCustomers, selectedLocations); }, ev);
                                }, callbacks: {
                                    onDeleteBadDataItem: function (id) {
                                        return onDeleteBadDataItem(Endpoints.remastered.softRemoveBadDataItem, id, setConfirmData, function () { return fetch(state, dispatch, selectedCustomers, selectedLocations); }, function () { return setLoader(state, dispatch, true); });
                                    },
                                    onShowChartImages: function (row) { return onShowChartImages(row, setImagesModalData); },
                                    setSelectedReadingTypes: function (selectedReadingTypes) { return setSelectedReadingTypes(state, dispatch, selectedReadingTypes); },
                                    onClickFilterTag: function (tag) { return onClickFilterTag(state, dispatch, tag); },
                                    onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                                }, onSearch: function (search) {
                                    return onSearch(search, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedCustomers, selectedLocations);
                                    });
                                } })] }) }) }), _jsx(ImagesModal, { images: imagesModalData.images.filter(function (image) { return image; }), onCancel: function () { return setImagesModalData({ visible: false, images: [] }); }, visible: imagesModalData.visible })] }));
};
export default BadDataPage;
